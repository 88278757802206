import { Link, useLocation } from "react-router-dom";

const NavBar = ({}) =>
{
    const location = useLocation();
    const isActive = (path) =>
    {
        return location.pathname.startsWith(path) ? "active" : "";
    };


    return (<>

        {/* using bg-opacity-50 will cause the NAVBAR to be semi transparent! only way is to have a fixed color */}
        {/* https://www.w3schools.com/colors/colors_shades.asp */}
        <div className="fixed-top bg-secondaryX bg-opacity-50X py-1 fs-p15" align="center"
            style={{backgroundColor: "#D3D3D3"}}>
            <div className="row container template-width px-0 align-center flex-nowrap">
                <div className="col text-start text-nowrap">

                <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <a className="py-1 ps-0 pe-1 me-3" href="/" style={{ color: "black", textDecoration: "none" }}>Project<b>Batik</b></a>
                    <nav className="nav nav-masthead d-flex flex-nowrap">
                        <Link to="/app/sonic" className={`nav-link py-1 px-1 fw-bold ${isActive('/app/sonic')}`}>Sonic</Link>
                        <Link to="/app/pluto" className={`nav-link py-1 px-1 fw-bold ${isActive('/app/pluto')}`}>Pluto</Link>
                    </nav>
                </div>
                <a className="py-1 ps-1 pe-0 ms-1" href="/" style={{ color: "black", textDecoration: "none" }}>Settings</a>
                </div>

                </div>
            </div>
        </div>

        <div><br /><br /></div>
    </>);
}

export default NavBar;

import React, { useState } from "react";

const MAIN_PASSWORD = "sawit";
const DEBUG_PASSWORD = process.env.NODE_ENV === "development" ? MAIN_PASSWORD : "";

// RUNNING ON CLIENT!!!!!!! NOTE! this is to allow PASSWORD to be saved to session
// export default dynamic(() => Promise.resolve(Login), {ssr: false});

function Login({onPasswordValidated})
{
    const [password, setPassword] = useState(DEBUG_PASSWORD);

	const handlePasswordSubmit = (e) =>
	{
		// e.preventDefault();

		console.log("password submitted: " + password);
		if (password === MAIN_PASSWORD)
		{
			sessionStorage.setItem('passwordValidated', 'true');
			//window.location.reload();
			console.log("password VALID!")
			setIsPasswordValidated(true);
			setPasswordErrorMsg("Authenticated");
            onPasswordValidated(true);
		}
		else
		{
			console.log("ERROR!!! password INVALID!")
			setPasswordErrorMsg("Password not valid");
            onPasswordValidated(false);
		}

	}

	// To check if the password has been validated on subsequent pages:
	const [isPasswordValidated, setIsPasswordValidated] = useState(sessionStorage.getItem('passwordValidated') === 'true');
	// const isPasswordValidated = sessionStorage.getItem('passwordValidated') === 'true';
	const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
	// console.log("isPasswordValidated: " + isPasswordValidated);

    return (<>
        <h3 className="mt-4 mb-4 fw-bold">Project Batik</h3>
        <div className="text-danger fs-5">Warning. This is a highly restricted site.</div>
        <div className="text-secondary">If you believe you should have access, please contact your friendly sempoa.ai representative</div>

        <div className="input-group input-group-sm mt-4 mb-2" style={{width: "300px"}}>
            <span className="input-group-text" id="inputGroup-password">Password</span>
            <input type="password" className="form-control" aria-label="Password"
                aria-describedby="inputGroup-password"
                style={{textAlign: "right"}}
                value={password}
                onChange={e => { setPassword(e.target.value) }}
                onKeyDown={(e) => { if (e.key === "Enter")
                {
                    e.preventDefault();
                    handlePasswordSubmit();
                }}}
                />
            <button id="passwordSubmit" className="btn btn-secondary" type="button" onClick={e => handlePasswordSubmit()}>Submit</button>
            <button id="passwordReset" className="btn btn-danger" type="button" onClick={e =>
            {
                e.preventDefault();
                setPassword("");
                sessionStorage.setItem('passwordValidated', false);
                setIsPasswordValidated(false);
                setPasswordErrorMsg("");
            }}>Reset</button>
        </div>

        {isPasswordValidated ? <div className="text-success fs-8 fw-semibold text-opacity-50">Authenticated</div> : <div className="text-danger fs-8 fw-semibold">NOT Authenticated</div> }
        <br />
        <div className="text-danger">{passwordErrorMsg}</div>
    </>);
}
export default Login;

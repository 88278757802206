// displays list of jobs

import React, { useEffect, useMemo, useState } from "react";
import { ENV_TYPE, HOST, PYTHON_PORT } from "../Config.js";
import useFetch from "../components/useFetch.js";
import { exportToCSV, formatDateString } from "../components/Utils.js";
import PaginationComponent from "../components/PaginationComponent.js";
import SonicImageView from "./SonicImageView.js";
import SonicTabs from "./SonicTabs.js";
import { useSearchParams } from "react-router-dom";
import SortableTable from "../components/SortableTable.js";
import { getFruitFormatValue, getFruitHeaderAlignment, getFruitHeaderName, handlerFruitColDate,
         handlerFruitColImgName } from "./helper/ColFormatInferFruit.js";
import RestQuery from "../components/RestQuery.js";

// to get localhost SSL certificate to work on test computer, need to add SSL of rest server directly
// to mac keychain and set to always trust. must also ensure cors middleware is setup in rest python code,
// to allow origin of react: https://m3.local:3000
const inferFruitUrl = "https://" + HOST + ":" + PYTHON_PORT + "/sonic/inferencefruit";

// const flagListUrl = "https://" + HOST + ":" + PYTHON_PORT + "/sonic/getflags";
const flagListUrl = "https://" + HOST + ":" + PYTHON_PORT + "/sonic/getflags";

const ROWS_PER_PAGE = 50;

// columns to display in list
// NOTE: HACK has sumConfidence (for job/report level real averaging)
const colList = ["imgName", "totalBunches", "mainCount", "diff", "meanConfidence", "createDate",
    "imgID", "imgCount", "estCode", "harvestAfd", "harvestBlock", "harvestPlatformName", "createBy"];
const colListFull = ["imgName", "totalBunches", "mainCount", "diff", "meanConfidence", "error%",
    "width", "height", "inferenceDuration", "inferenceDate", "createDate", "imgID", "imgCount", "harvesterCount",
    "idTPanen", "estate", "estCode", "harvestAfd", "harvestBlock", "harvestPlatformName", "createBy",
    "imgLocation", "mechanizationFlag"];

// items per page dropdown list
const itemsPerPageList = [10, 20, 50, 100, 200];

// process each row of inference fruit and display output
// not to be confused with ByTph where it goes through each record and displays grouped images
// function SonicInferByImage({estate="TSB", queryDate="20240827"})
function SonicInferByImage({estate, queryDate, sortCol, sortType="asc", isFlagQuery=false})
{
    // USE URL PARAMS IF AVAILABLE
	const [searchParams] = useSearchParams();
    var paramEstate = searchParams.get("estate");
    var paramDate = searchParams.get("date");
    var paramSortCol = searchParams.get("sortCol");
    var paramSortType = searchParams.get("sortType"); // asc/desc
    if (paramEstate) estate = paramEstate;
    if (paramDate) queryDate = paramDate;
    if (paramSortCol) sortCol = paramSortCol;
    if (paramSortType) sortType = paramSortType;

    var paramUserName = searchParams.get("userName");


    // pageType to display different views (this class is a controller)
    // 1. list (for all listing -  this is the default)
    // 2. img (for single image with prev/next buttons)
    const [pageType, setPageType] = useState("list");

    // pagination
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const handlePageChange = (pageNo) =>
    {
        // console.log("page change: " + pageNo);
        setCurrentPageNo(pageNo);
    };

    // ITEMS PER PAGE SELECTION
    const [itemsPerPage, setItemsPerPage] = useState(ROWS_PER_PAGE);
    const handleItemsPerPageChange = (event) =>
    {
        setItemsPerPage(event.target.value);
    };

    // SERVER SIDE SORTING: TO ACCOUNT FOR PAGINATION
    const [querySortCol, setQuerySortCol] = useState(sortCol);
    const [querySortType, setQuerySortType] = useState(sortType);

    // REST QUERY
    const [queryInferFruitUrl, setQueryInferFruitUrl] = useState();
    useEffect(() =>
    {
        // no url if params for estate or queryDate is null
        var url;
        if (!isFlagQuery)
            url = (!estate || !queryDate) ? ""
                : inferFruitUrl + "?estate=" + estate + "&date=" + queryDate
                    + "&page=" + currentPageNo + "&itemsPerPage=" + ROWS_PER_PAGE
                    // encodeURIComponent() is needed here to deal with columns like "error%"
                    + (querySortCol ? "&sortCol=" + encodeURIComponent(querySortCol) : "")
                    + (querySortType ? "&sortType=" + querySortType : "")
                    + (itemsPerPage ? "&itemsPerPage=" + itemsPerPage : "");
        else
        {
            url = flagListUrl + "?"
                + "page=" + currentPageNo + "&itemsPerPage=" + ROWS_PER_PAGE
                + (paramUserName ? "&userName=" + paramUserName : "");
        }
        // console.log("url: " + url);
        setQueryInferFruitUrl(url);
    }, [estate, currentPageNo, querySortCol, querySortType, itemsPerPage])
    // if querySortType is NOT added here, it doesnt update the query url!


    // for viewing single image: when pageType === img
    const [viewImgLocation, setViewImgLocation] = useState(null);
    const [viewImgData, setViewImgData] = useState(null);
    const [viewImgSelectIndex, setViewImgSelectIndex] = useState();

    const [inferFruitVersion, setInferFruitVersion] = useState(0);
    const { data: inferFruitData, isPending: inferFruitPending, error: inferFruitError } = useFetch({
        // url: inferFruitUrl + "?estate=GAN&date=20240827&page=1&itemsPerPage=50",
        url: queryInferFruitUrl,
        version: inferFruitVersion, isAuth: false, excludeHeader: true});

    // checkbox to display FULL DETAILS of table
    const [isFullDetails, setFullDetails] = useState(false);
    const handleFullDetailsChange = (event) =>
    {
        setFullDetails(event.target.checked);
    };


    // IGNORE column inferFruitData.data.annotations (since its a long list and will pose issues with nested structure)
    const handleExportCSV = () =>
    {
        // note, we only need to pass it the "data" key
        if (inferFruitData && inferFruitData.data)
            exportToCSV(inferFruitData.data, "fruit_data.csv", ["annotations"]);
        else
            console.warn("No data to export")
    };


    // SORTED DATA: callback from SortableTable so that sortedData can be passed to SonicViewImage
    const [sortedData, setSortedData] = useState([]);
    const handleSortedData = (data) =>
    {
        setSortedData(data);
    };




    // LOAD FLAGS (for images)
    // TODO: load all for user? cap query to estate and date?
    // flagData: contains dict of flags (TODO: change to key=value for fast lookup?)
    const { data: flagData, isPending: flagDataIsPending, error: flagDataError } = useFetch(
        // flagListUrl + "?userName=testUser", 0, true);
        { url: flagListUrl + "?userName=testUser",
          version: 0, isAuth: false, excludeHeader: true});
    // have to do this so that setFlagImgData can be used further down the components eg SonicImageView)
    const [flagImgMap, setFlagImgMap] = useState();
    useEffect(() =>
    {
        if (flagData && flagData.data)
        {
            // once inferFruitData has been loaded/change, we alter the structure to key=value pair
            // for fast lookup
            var flagMap = {};
            for (var i = 0; i < flagData.data.length; i++)
            {
                var flag = flagData.data[i];
                flagMap[flag.imgName] = true;
            }
            setFlagImgMap(flagMap);
            // console.log(flagMap)
        }
    }, [inferFruitData]);



    return (<>


        {/* ===== TABS ===== */}
        <SonicTabs headId={ isFlagQuery ? 2 : 1} />


        {/* ===== REPORT BY ===== */}
        <div className="fs-p13 text-purple-400">Inference by Image</div>


        {/* ===== TITLE: DETAILS, ESTATE, QUERYDATE ===== */}
        { (estate && queryDate) && !isFlagQuery && <div className="mb-3">
            Estate: <b>{estate}</b>, Date: <b>{formatDateString(queryDate)}</b>
            { inferFruitData && inferFruitData.data && inferFruitData.data.length > 0 &&
            <div className="fs-p12">Page {currentPageNo} of {(!inferFruitData.itemsPerPage
                || inferFruitData.itemsPerPage === 0) ? 1 : Math.ceil(inferFruitData.rows / inferFruitData.itemsPerPage)}
                <span className="">({inferFruitData.data.length} of {inferFruitData.rows} row{inferFruitData.rows === 1 ? ""
                : "s"})</span></div>}
        </div>}

        {isFlagQuery && <div className="mb-3">
            <span className="fw-bold">Flagged by </span>
            <span className="text-purple-500 fw-bold">{paramUserName ? paramUserName : "All Users"}</span>
            { inferFruitData && inferFruitData.data && inferFruitData.data.length > 0 &&
            <div className="fs-p12">Page {currentPageNo} of {(!inferFruitData.itemsPerPage
                || inferFruitData.itemsPerPage === 0) ? 1 : Math.ceil(inferFruitData.rows / inferFruitData.itemsPerPage)
                } <span className="">({inferFruitData.data.length} of {inferFruitData.rows} row{inferFruitData.rows === 1 ? ""
                : "s"})</span></div>}
        </div>}

        { (!estate || !queryDate) && !isFlagQuery &&
            <div>
                <br />
                <div className="text-danger">No query selected.</div>
                <div>Please select an estate and date for review <a href="/app/sonic/infer/listreport">here</a></div>
                <br/><br/>
            </div> }

        { inferFruitPending
            && <div className="spinner-border spinner-border-sm me-2 text-secondary fs-7 mt-1" role="status"></div>}

        { inferFruitError
            && <div className="text-danger fw-bold">{"Inference Error: " + inferFruitError}<br /><br /></div>}

        { inferFruitData && (!inferFruitData.data || inferFruitData.data.length === 0)
            && <div className="text-danger my-1">No Fruit Inference available<br /></div>}


        {/* ===== SINGLE IMAGE VIEW ===== */}
        { pageType === "img" && inferFruitData && inferFruitData.data && inferFruitData.data.length > 0 && <div className="text-black">
            <SonicImageView imgLocation={viewImgLocation}
                imgData={viewImgData}
                rowIndex={viewImgSelectIndex}
                setPageType={setPageType}
                inferFruitData={sortedData}
                flagImgMap={flagImgMap}
                setFlagImgMap={setFlagImgMap}
                />
                {/* inferFruitData={inferFruitData.data} /> */}
        </div>}


        {/* ===== LIST VIEW ===== */}
        { pageType === "list" && inferFruitData && inferFruitData.data && inferFruitData.data.length > 0 && <div className="text-black">

            {/* <div>data: {inferFruitData.data.length}</div>
            <div>rows: {inferFruitData.rows}</div>
            <div>pageNo: {inferFruitData.page}</div>
            <div>itemsPerPage: {inferFruitData.itemsPerPage}</div> */}
            {/* <div>{JSON.stringify(inferFruitData)}</div> */}




            {/* ===== BUTTONS: RELOAD, EXPORT, FULL DETAILS ===== */}
            <div className="d-flex align-items-baseline mb-1 mt-1">
                <button type="button" className="btn btn-info btn-sm py-0"
                    onClick={(e) => setInferFruitVersion(inferFruitVersion + 1)}>Reload <i className="bi bi-arrow-repeat"></i></button>

                <button type="button" className="btn btn-info btn-sm py-0 ms-2"
                    onClick={(e) => handleExportCSV()}>Export <i className="bi bi-cloud-download"></i></button>

                <span className="form-check fs-p14 ms-3">
                    <input className="form-check-input" type="checkbox" value="" id="checkFullDetails"
                                checked={isFullDetails} onChange={handleFullDetailsChange}/>
                    <label className="form-check-label" htmlFor="checkFullDetails">
                        Details
                    </label>
                </span>
            </div>


            {/* ===== DATA QUERY SORTING OPTIONS (changes the order of sort that IMPACTS PAGINATION TOO) ===== */}
            <div className="d-flex align-items-baseline mb-1 mt-1 fs-p14">
                {/* DROPDOWN: SORT COLUMN */}
                <div className="dropdown me-2">
                    <button className="btn btn-sm btn-secondary dropdown-toggle py-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        { querySortCol ? <>Sort <span className="text-warning">{getFruitHeaderName(querySortCol)}</span></> : "Sort By"}
                    </button>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item bg-secondary bg-opacity-25" href="#"
                            onClick={(e) => setQuerySortCol()}>No Sort</a></li>
                        { colListFull.map((colName, idx) =>
                            (<li key={idx}><a className="dropdown-item" href="#"
                                onClick={(e) => setQuerySortCol(colName)}
                                >{getFruitHeaderName(colName)}</a></li>))}
                    </ul>
                </div>
                {/* CHECKBOX: SORT ORDER TYPE (ASC/DESC) */}
                <div className="form-check me-4">
                    <input className="form-check-input" type="checkbox" value="" id="checkboxSortOrder"
                        onChange={(e) => setQuerySortType(e.target.checked ? "asc" : "desc")}
                        checked={querySortType==="asc"} />
                    <label className="form-check-label" htmlFor="checkboxSortOrder">Ascending</label>
                </div>
                {/* DROPDOWN: ITEMS PER PAGE */}
                <div className="d-flex align-items-center">
                    <div className="dropdown ms-2 me-1">
                        <button className="btn btn-sm btn-secondaryx bg-secondary-subtle dropdown-toggle py-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {itemsPerPage}
                        </button>
                        <ul className="dropdown-menu">
                            { itemsPerPageList.map((itemsPerPage, idx) =>
                                (<li key={idx}><a className="dropdown-item" href="#"
                                    onClick={(e) => handleItemsPerPageChange({target: {value: itemsPerPage}})}
                                    >{itemsPerPage}</a></li>))}
                        </ul>
                    </div>
                    Rows
                </div>
            </div>


            {/* <PaginationComponent
                totalRows={inferFruitData.rows}
                currentPageNo={inferFruitData.page}
                itemsPerPage={inferFruitData.itemsPerPage}
                onPageChange={handlePageChange}
                includeStatsLine={false}
            /> */}

            <SortableTable tableData={inferFruitData.data}
                onSort={handleSortedData} //this is the callback after sort done inside sortableTable
                colList={colList}
                colListFull={colListFull}
                isFullDetails={isFullDetails}
                handlerColHeaderRename={getFruitHeaderName}
                handlerColHeaderAlign={getFruitHeaderAlignment}
                handlerColFormatValue={getFruitFormatValue}
                // dict of columns to totally reformat (ie imgName => <a href>XXXXX_</a>)
                handlerColReformatMap={{ "imgName": handlerFruitColImgName,
                                         "createDate": handlerFruitColDate,
                                         "inferenceDate": handlerFruitColDate
                                      }}
            />


            {/* ===== PAGINATION ===== */}
            <br />
            <PaginationComponent
                totalRows={inferFruitData.rows}
                currentPageNo={inferFruitData.page}
                itemsPerPage={inferFruitData.itemsPerPage}
                onPageChange={handlePageChange}
                includeStatsLine={true}
            />

        </div>}

    </>);


    // deals specifically with displaying the column for imgName, called inside SortableTable
    function handlerFruitColImgName(aValue, dataIdx, data, isFullDetails)
    {
        // if isFullDetails, then show full imageName
        // if not isFullDetails, then just show first XXXX_ of imageName
        // will always link
        return (
        <a href="#" onClick={(e) =>
            {
                e.preventDefault();
                setPageType("img");
                setViewImgLocation(data.imgLocation);
                setViewImgData(data);
                setViewImgSelectIndex(dataIdx);
            }}>
            {isFullDetails ? data.imgName : data.imgName ? data.imgName.split("_")[0] : data.imgName}
        </a>);
    }


}
export default SonicInferByImage;
// displays list of jobs

import React, { useEffect, useMemo, useState } from "react";
// import RestQuery from "../components/RestQuery";
import { HOST, PYTHON_PORT } from "../Config.js";
import useFetch from "../components/useFetch.js";
import { exportToCSV, formatDateString } from "../components/Utils.js";
import PaginationComponent from "../components/PaginationComponent.js";
import SonicTabs from "./SonicTabs.js";
import { useSearchParams } from "react-router-dom";
import SortableTable from "../components/SortableTable.js";
import { getTphFormatValue, getTphHeaderAlignment, getTphHeaderName, handlerTphColDate, handlerTphColImgName } from "./helper/ColFormatInferTph.js";
import SonicImgViewByTPH from "./SonicImgViewByTPH.js";

// to get localhost SSL certificate to work on test computer, need to add SSL of rest server directly to mac keychain and set to always trust. must also ensure cors middleware is setup in rest python code, to allow origin of react: https://m3.local:3000
const recordsUrl = "https://" + HOST + ":" + PYTHON_PORT + "/sonic/records";
// https://m3.local:8001/sonic/records?estate=CMA&date=20240828&page=1&itemsPerPage=50&sortType=asc&itemsPerPage=50

const ROWS_PER_PAGE = 50;

// columns to display in list
// NOTE: HACK has sumConfidence (for job/report level real averaging)
const colList = ["idTPanen", "totalBunches", "mainCount", "diff", "meanConfidence", "createDate", "imgCount", "inferenceCount", "estCode", "harvestAfd", "harvestBlock", "harvestPlatformName", "createBy"];
const colListFull = ["idTPanen", "totalBunches", "mainCount", "diff", "meanConfidence", "error%",
    "inferenceDuration", "inferenceDate",
    "createDate", "imgCount", "inferenceCount", "harvesterCount", "estate", "estCode", "harvestAfd", "harvestBlock", "harvestPlatformName", "createBy",
    "dRecord2Tph",
    "dTph2Tph",
    "mechanizationFlag"];

// OPTIONAL: to show sorted SortBy list
// contains map: { original, adjusted }
// const sortedColListFull = [...colListFull].sort((a, b) => {
//     const adjustedA = getTphHeaderName(a);
//     const adjustedB = getTphHeaderName(b);
//     return adjustedA.localeCompare(adjustedB);
// }).map(colName => ({
//     original: colName,
//     adjusted: getTphHeaderName(colName)
// }));

// items per page dropdown list
const itemsPerPageList = [10, 20, 50, 100, 200];

// process each row of inference fruit and display output
// not to be confused with ByTph where it goes through each record and displays grouped images
// function SonicInferByImage({estate="TSB", queryDate="20240827"})
function SonicInferByTph({estate, queryDate, sortCol, sortType="asc"})
{
    // USE URL PARAMS IF AVAILABLE
	const [searchParams] = useSearchParams();
    var paramEstate = searchParams.get("estate");
    var paramDate = searchParams.get("date");
    var paramSortCol = searchParams.get("sortCol");
    var paramSortType = searchParams.get("sortType"); // asc/desc
    if (paramEstate) estate = paramEstate;
    if (paramDate) queryDate = paramDate;
    if (paramSortCol) sortCol = paramSortCol;
    if (paramSortType) sortType = paramSortType;

    // pageType to display different views (this class is a controller)
    // 1. list (for all listing -  this is the default)
    // 2. img (for single image with prev/next buttons)
    const [pageType, setPageType] = useState("list");

    // pagination
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const handlePageChange = (pageNo) =>
    {
        // console.log("page change: " + pageNo);
        setCurrentPageNo(pageNo);
    };

    // ITEMS PER PAGE SELECTION
    const [itemsPerPage, setItemsPerPage] = useState(ROWS_PER_PAGE);
    const handleItemsPerPageChange = (event) =>
    {
        setItemsPerPage(event.target.value);
    };

    // SERVER SIDE SORTING: TO ACCOUNT FOR PAGINATION
    const [querySortCol, setQuerySortCol] = useState(sortCol);
    const [querySortType, setQuerySortType] = useState(sortType);

    // REST QUERY
    const [queryInferFruitUrl, setQueryInferFruitUrl] = useState();
    useEffect(() =>
    {
        const addSortColCreateDate = querySortCol === "createDate" ? "" : ",createDate";
        const addSortTypeCreateDate = querySortCol === "createDate" ? "" : ",asc";
        const querySortColList = (querySortCol ? "&sortCol=" + encodeURIComponent(querySortCol) + addSortColCreateDate : "")
        // no url if params for estate or queryDate is null
        const url = (!estate || !queryDate) ? ""
            : recordsUrl + "?estate=" + estate + "&date=" + queryDate
                + "&page=" + currentPageNo + "&itemsPerPage=" + ROWS_PER_PAGE
                // encodeURIComponent() is needed here to deal with columns like "error%"
                // + (querySortCol ? "&sortCol=" + encodeURIComponent(querySortCol) : "")
                + querySortColList
                + (querySortType ? "&sortType=" + querySortType + addSortTypeCreateDate: "")
                + (itemsPerPage ? "&itemsPerPage=" + itemsPerPage : "");
        // console.log("url: " + url);
        setQueryInferFruitUrl(url);
    }, [estate, currentPageNo, querySortCol, querySortType, itemsPerPage])
    // if querySortType is NOT added here, it doesnt update the query url!


    // for viewing single image: when pageType === img
    // const [viewImgLocation, setViewImgLocation] = useState(null);
    const [viewImgData, setViewImgData] = useState(null);
    const [viewImgSelectIndex, setViewImgSelectIndex] = useState();

    const [inferFruitVersion, setInferFruitVersion] = useState(0);
    const { data: inferFruitData, isPending: inferFruitPending, error: inferFruitError } = useFetch({
        // url: inferFruitUrl + "?estate=GAN&date=20240827&page=1&itemsPerPage=50",
        url: queryInferFruitUrl,
        version: inferFruitVersion, isAuth: false, excludeHeader: true});

    // checkbox to display FULL DETAILS of table
    const [isFullDetails, setFullDetails] = useState(false);
    const handleFullDetailsChange = (event) =>
    {
        setFullDetails(event.target.checked);
    };


    // IGNORE column inferFruitData.data.annotations (since its a long list and will pose issues with nested structure)
    const handleExportCSV = () =>
    {
        // note, we only need to pass it the "data" key
        if (inferFruitData && inferFruitData.data)
            exportToCSV(inferFruitData.data, "fruit_data.csv", ["annotations"]);
        else
            console.warn("No data to export")
    };


    // SORTED DATA: callback from SortableTable so that sortedData can be passed to SonicViewImage
    const [sortedData, setSortedData] = useState([]);
    const handleSortedData = (data) =>
    {
        setSortedData(data);
    };


    return (<>

        {/* ===== TABS ===== */}
        <SonicTabs headId={1} />


        {/* ===== REPORT BY ===== */}
        <div className="fs-p13 text-purple-400">Inference by TPH</div>


        {/* ===== TITLE: DETAILS, ESTATE, QUERYDATE ===== */}
        { (estate && queryDate) && <div className="mb-3">
            Estate: <b>{estate}</b>, Date: <b>{formatDateString(queryDate)}</b>
            { inferFruitData && inferFruitData.data && inferFruitData.data.length > 0 &&
            <div className="fs-p12">Page {currentPageNo} of {(!inferFruitData.itemsPerPage || inferFruitData.itemsPerPage === 0) ? 1 : Math.ceil(inferFruitData.rows / inferFruitData.itemsPerPage)} <span className="">({inferFruitData.data.length} of {inferFruitData.rows} row{inferFruitData.rows === 1 ? "" : "s"})</span></div>}
        </div>}




        { (!estate || !queryDate) &&
            <div>
                <br />
                <div className="text-danger">No query selected.</div>
                <div>Please select an estate and date for review <a href="/app/sonic/infer/listreport">here</a></div>
                <br/><br/>
            </div> }

        { inferFruitPending
            && <div className="spinner-border spinner-border-sm me-2 text-secondary fs-7 mt-1" role="status"></div>}

        { inferFruitError
            && <div className="text-danger fw-bold">{"Inference Error: " + inferFruitError}<br /><br /></div>}

        { inferFruitData && (!inferFruitData.data || inferFruitData.data.length === 0)
            && <div className="text-danger my-1">No Fruit Inference available<br /></div>}


        {/* ===== SINGLE IMAGE VIEW ===== */}
        { pageType === "img" && inferFruitData && inferFruitData.data && inferFruitData.data.length > 0 && <div className="text-black">
            <SonicImgViewByTPH
                imgData={viewImgData}
                rowIndex={viewImgSelectIndex}
                setPageType={setPageType}
                inferFruitData={sortedData} />
        </div>}


        {/* ===== LIST VIEW ===== */}
        { pageType === "list" && inferFruitData && inferFruitData.data && inferFruitData.data.length > 0 && <div className="text-black">

            {/* <div>data: {inferFruitData.data.length}</div>
            <div>rows: {inferFruitData.rows}</div>
            <div>pageNo: {inferFruitData.page}</div>
            <div>itemsPerPage: {inferFruitData.itemsPerPage}</div> */}
            {/* <div>{JSON.stringify(inferFruitData)}</div> */}




            {/* ===== BUTTONS: RELOAD, EXPORT, FULL DETAILS ===== */}
            <div className="d-flex align-items-baseline mb-1 mt-1">
                <button type="button" className="btn btn-info btn-sm py-0"
                    onClick={(e) => setInferFruitVersion(inferFruitVersion + 1)}>Reload <i className="bi bi-arrow-repeat"></i></button>

                <button type="button" className="btn btn-info btn-sm py-0 ms-2"
                    onClick={(e) => handleExportCSV()}>Export <i className="bi bi-cloud-download"></i></button>

                <span className="form-check fs-p14 ms-3">
                    <input className="form-check-input" type="checkbox" value="" id="checkFullDetails"
                                checked={isFullDetails} onChange={handleFullDetailsChange}/>
                    <label className="form-check-label" htmlFor="checkFullDetails">
                        Details
                    </label>
                </span>
            </div>


            {/* ===== DATA QUERY SORTING OPTIONS (changes the order of sort that IMPACTS PAGINATION TOO) ===== */}
            <div className="d-flex align-items-baseline mb-1 mt-1 fs-p14">
                {/* DROPDOWN: SORT COLUMN */}
                <div className="dropdown me-2">
                    <button className="btn btn-sm btn-secondary dropdown-toggle py-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        { querySortCol ? <>Sort <span className="text-warning">{getTphHeaderName(querySortCol)}</span></> : "Sort By"}
                    </button>
                    <ul className="dropdown-menu">
                        <li><a className="dropdown-item bg-secondary bg-opacity-25" href="#" onClick={(e) => setQuerySortCol()}>
                            No Sort
                        </a></li>
                        {/* { sortedColListFull.map(({ original, adjusted }) =>
                        <li key={original}><a className="dropdown-item" href="#"
                                onClick={(e) => setQuerySortCol(original)}
                                >{adjusted}</a></li>)} */}
                        { colListFull.map((colName, idx) =>
                            (<li key={idx}><a className="dropdown-item" href="#"
                                onClick={(e) => setQuerySortCol(colName)}
                                >{getTphHeaderName(colName)}</a></li>))}
                    </ul>
                </div>
                {/* CHECKBOX: SORT ORDER TYPE (ASC/DESC) */}
                <div className="form-check me-4">
                    <input className="form-check-input" type="checkbox" value="" id="checkboxSortOrder"
                        onChange={(e) => setQuerySortType(e.target.checked ? "asc" : "desc")}
                        checked={querySortType==="asc"} />
                    <label className="form-check-label" htmlFor="checkboxSortOrder">Ascending</label>
                </div>
                {/* DROPDOWN: ITEMS PER PAGE */}
                <div className="d-flex align-items-center">
                    <div className="dropdown ms-2 me-1">
                        <button className="btn btn-sm btn-secondaryx bg-secondary-subtle dropdown-toggle py-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {itemsPerPage}
                        </button>
                        <ul className="dropdown-menu">
                            { itemsPerPageList.map((itemsPerPage, idx) =>
                                (<li key={idx}><a className="dropdown-item" href="#"
                                    onClick={(e) => handleItemsPerPageChange({target: {value: itemsPerPage}})}
                                    >{itemsPerPage}</a></li>))}
                        </ul>
                    </div>
                    Rows
                </div>
            </div>


            {/* <PaginationComponent
                totalRows={inferFruitData.rows}
                currentPageNo={inferFruitData.page}
                itemsPerPage={inferFruitData.itemsPerPage}
                onPageChange={handlePageChange}
                includeStatsLine={false}
            /> */}

            <SortableTable tableData={inferFruitData.data}
                onSort={handleSortedData} //this is the callback after sort done inside sortableTable
                colList={colList}
                colListFull={colListFull}
                isFullDetails={isFullDetails}
                handlerColHeaderRename={getTphHeaderName}
                handlerColHeaderAlign={getTphHeaderAlignment}
                handlerColFormatValue={getTphFormatValue}
                // dict of columns to totally reformat (ie imgName => <a href>XXXXX_</a>)
                handlerColReformatMap={{ "idTPanen": handlerTphColIdTPanen,
                                         "createDate": handlerTphColDate,
                                         "inferenceDate": handlerTphColDate
                                      }}
            />


            {/* ===== PAGINATION ===== */}
            <br />
            <PaginationComponent
                totalRows={inferFruitData.rows}
                currentPageNo={inferFruitData.page}
                itemsPerPage={inferFruitData.itemsPerPage}
                onPageChange={handlePageChange}
                includeStatsLine={true}
            />

        </div>}

    </>);



    // deals specifically with displaying the column for imgName, called inside SortableTable
    // data: actual row (item) of listing/report
    function handlerTphColIdTPanen(aValue, dataIdx, data, isFullDetails)
    {
        // if isFullDetails, then show full imageName
        // if not isFullDetails, then just show first XXXX_ of imageName
        // will always link
        return (
        <a href="#" onClick={(e) =>
            {
                e.preventDefault();
                setPageType("img");
                // setViewImgLocation(data.imgLocation);
                setViewImgData(data);
                setViewImgSelectIndex(dataIdx);
            }}>
            {data.idTPanen}
        </a>);
    }

}
export default SonicInferByTph;
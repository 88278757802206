import React, { useEffect, useMemo, useRef, useState } from "react";
import { cellStyle } from "./Utils";


// using memo here in the component prevents rerender of all these data
// the trigger is due to the parent component rerendering, trigger this to rerender
// by wrapping inside memo, it will stop it!
// tableData: this MUST be a list[dict]
const SortableTable = React.memo(({
        tableData,
        onSort, // callback to parent so that the sorted table can be passed outside this class
        colList, // default (not full list)
        colListFull,
        isFullDetails, // if true, then use colListFull for full details. if not, use colList
        handlerColHeaderRename, // method to call to rename column header (if at all) delegated pattern to be generic
        handlerColHeaderAlign, // method to call to determine column text alignment (if at all) delegated pattern to be generic
        handlerColFormatValue, // method to call to format/value (if at all) delegated pattern to be generic
        handlerColReformatMap // to totally reformat a column with ahref + value parsing, eg: { "imgName": handlerColImgName }
}) =>
{
    if (!colListFull) colListFull = colList;

    // ===== DYNAMIC SORTING =====
    const [sortOrder, setSortOrder] = useState('default'); // Initialize with 'default'
    const [sortColumn, setSortColumn] = useState(null); // Track the sorted column

    // Handle column click
    const handleColumnClick = (columnKey) =>
    {
        // Toggle sortOrder: 'ascending' -> 'descending' -> 'default'
        setSortOrder(prevOrder =>
        {
            if (prevOrder === 'ascending') return 'descending';
            if (prevOrder === 'descending') return 'default';
            return 'ascending';
        });
        setSortColumn(columnKey);
        // console.log("column clicked: " + columnKey + ", order: " + sortOrder);
    };


    // Sort data based on the selected column and sortOrder
    const sortedData = useMemo(() =>
    {
        // for sorting, need this to be a LIST
        if (!tableData) return [];
        // 16/9/2024: unsure whether code needs to be added as key? must be a bug
        // const sortedArray = Object.keys(tableData).map((code) => ({ code, ...tableData[code] }));
        const sortedArray = Object.keys(tableData).map((code) => ({ ...tableData[code] }));
        //console.log(JSON.stringify(sortedArray));

        if (sortOrder === 'default')
            return sortedArray; // Return the original order

        // SORT LOGIC accounts for NUMBER OR STRING (since dates are all YYYMMDD HHMMSS, dont have to process explicitly as dates)
        sortedArray.sort((a, b) =>
        {
            const aValue = a[sortColumn] ?? '';
            const bValue = b[sortColumn] ?? '';

            // If both values are numbers, do numeric comparison
            if (typeof aValue === 'number' && typeof bValue === 'number') {
              return sortOrder === 'ascending' ? aValue - bValue : bValue - aValue;
            }

            // Otherwise, convert to strings and compare
            const stringA = String(aValue).toLowerCase();
            const stringB = String(bValue).toLowerCase();

            return sortOrder === 'ascending'
              ? stringA.localeCompare(stringB)
              : stringB.localeCompare(stringA);
        });
        return sortedArray;
    }, [tableData, sortColumn, sortOrder]);


    // CALLBACK for sortedData!
    useEffect(() =>
    {
        onSort(sortedData);
    }, [sortedData, onSort]);



    return (
        <table className="table table-hover w-auto table-condensed mb-0 fs-p13" align="start">

        {/* HEADER ROW */}
        <thead>
        {sortedData &&
            <tr>
                { (isFullDetails ? colListFull : colList).map((colName, colIdx) =>
                {
                    //getFruitHeaderName = (headerName)
                    const value = handlerColHeaderRename(colName);
                    const textAlign = handlerColHeaderAlign ? handlerColHeaderAlign(colName) : "";
                    // style={{...cellStyle, paddingLeft: "0px"}}
                    return (<th key={colIdx} style={colIdx===0 ? {...cellStyle, paddingLeft: "0px"} : cellStyle}
                        className={textAlign}
                        onClick={() => handleColumnClick(colName)}>{value}</th>);
                })}
            </tr>}
        </thead>

        {/* DATA ROWS */}
        <tbody>
        {sortedData && sortedData.map((item, mainIdx) =>
        {
            return (
                <tr key={mainIdx}>
                    { (isFullDetails ? colListFull : colList).map((colName, colIdx) =>
                    {
                        //getFruitFormatValue = (colName, aValue, item) // returns [value, classname]
                        var [value, aClassName] = handlerColFormatValue(colName, item[colName], item);

                        if (colName in handlerColReformatMap)
                            // handlerColImgName(aValue, dataIdx, data)
                            value = handlerColReformatMap[colName](value, mainIdx, item);

                        return (<td key={colIdx} style={colIdx===0 ? {...cellStyle, paddingLeft: "0px"} : cellStyle}
                            className={aClassName}>{value}</td>);
                    })}
                </tr>
        )})}
        </tbody>

    </table>);
});

export default SortableTable;
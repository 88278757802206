import { useEffect, useState } from "react";
import  secureLocalStorage  from  "react-secure-storage";

// generic hook for loading json file via REST
// this hook doesnt pass in token
// url: rest query that returns json
// version: increment/change it for auto refresh since it triggers the useEffect() call
// isAuth: if true, then will pass in the bearer token when calling fetch. for urls that require jwt auth tokens
// excludeHeader: default to false (ie always have content-type etc). but for hk observatory, it doesnt want header
const useFetch = ({url, version, isAuth, excludeHeader}) =>
{
    //if (!isAuth) isAuth = true;

    const [data, setData] = useState(null);
    const [isPending, setIsPending] = useState(true);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [updateTime, setUpdateTime] = useState(null);

    //console.log("useFetch> url: " + url + ", version: " + version);

    useEffect(() =>
    {
        //if (!url) return;
        if (!url || url === null || url === "") // reset data if url is null or blank
        {
            setData(null); // setting this to null will reset the data, and react will use this to update its rerender cache
            setIsPending(false);
            setError(null);
            setErrorCode(null);
            setUpdateTime(new Date());
            return //{data, isPending, error, updateTime };
        }

        //if (url === null)     return { data, isPending, error };
        // reset in case the call is done via version-increment!
        setData(null);
        setIsPending(true);
        setError(null);

        const abortController = new AbortController();

        let headers;
        if (isAuth)
        {
            const token = JSON.parse(secureLocalStorage.getItem("token"));
            headers = { 'Content-type': 'application/json',
                        'Authorization': 'Bearer ' + token };
        }
        else if (excludeHeader) // for hk observatory
            headers = {};
        else
            headers = { 'Content-type': 'application/json' };

        fetch(url,
        {
            method: "GET",
            //headers: { 'Content-type': 'application/json' },
            headers: headers,
            signal: abortController.signal
        })
        .then(res =>
        {
            //console.log("fetching... " + url);
            if (!res.ok) { throw new Error({ message: "unable to fetch data: " + url,
                                             status: res.status }); }
            return res.json();
        })
        .then(returnObj =>
        {
            setData(returnObj);
            setIsPending(false);
            setError(null);
            setErrorCode(null);
            // set time once query complete
            // set updateTime here and not outside the loop, or else date will be set on abort too!
            setUpdateTime(new Date());
        })
        .catch(err =>
        {
            if (err.name === "AbortError")
            {
                // if aborted, then dont care (assume going to make another call?!)
                //console.log("useFetch aborted: " + url);
            }
            else
            {
                console.log("ERROR useFetch: " + err.name + " -> " + err.message);
                setIsPending(false);
                setError(err.message);
                setErrorCode(err.status);
            }
        });

        // abort the fetch
        return () => abortController.abort();

    }, [url, version, isAuth, excludeHeader]);

    //console.log(data); // DEBUG
    return { data, isPending, error, errorCode, updateTime };
};

export default useFetch;

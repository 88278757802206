// displays list of jobs

import React, { useState } from "react";
// import RestQuery from "../components/RestQuery";
import { HOST, PYTHON_PORT } from "../Config.js";
import useFetch from "../components/useFetch.js";
import { exportToCSV } from "../components/Utils.js";
// import PaginationComponent from "../components/PaginationComponent.js";
import SonicTabs from "./SonicTabs.js";
import SortableTable from "../components/SortableTable.js";
import { getInferReportFormatValue, getInferReportHeaderAlignment, getInferReportHeaderName, handlerInferReportColQueryDate } from "./helper/ColFormatInferReport.js";

// to get localhost SSL certificate to work on test computer, need to add SSL of rest server directly to mac keychain and set to always trust. must also ensure cors middleware is setup in rest python code, to allow origin of react: https://m3.local:3000
const reportUrl = "https://" + HOST + ":" + PYTHON_PORT + "/sonic/report_list";

const colList = ["Report", "date", "estate", "count_records", "count_fruit", "meanTotalBunches", "meanMainCount", "meanConfidence"] // "endTime",];
// const colListFull = ["type", "estate", "queryDate", "description", "startTime", "endTime", "durationSeconds", "count", "meanTotalBunches", "meanMainCount", "medianTotalBunches", "medianMainCount", "meanConfidence", "meanConfidenceAvg", "meanTimePerImage", "userName", "hostName", "ipAddress"];
function SonicInferReport()
{
    const [reportVersion, setreportVersion] = useState(0);
    const { data: reportData, isPending: reportPending, error: reportError } = useFetch({url: reportUrl,
        version: reportVersion, isAuth: false, excludeHeader: true});

    const [currentPageNo, setCurrentPageNo] = useState(1);
    const handlePageChange = (pageNo) =>
    {
        // console.log("page change: " + pageNo);
        setCurrentPageNo(pageNo);
    };

    const handleExportCSV = () =>
    {
        // note, we only need to pass it the "data" key
        if (reportData && reportData.data)
            exportToCSV(reportData.data, 'report_data.csv');
        else
            console.warn("No data to export")
    };



    // ===== SORTABLE TABLE =====
    // SORTED DATA: callback from SortableTable so that sortedData can be passed to SonicViewImage
    const [sortedData, setSortedData] = useState([]);
    const handleSortedData = (data) =>
    {
        setSortedData(data);
    };

    return (<>

        {/* ===== TABS ===== */}
        <SonicTabs headId={0} />


        { reportPending
            && <div className="spinner-border spinner-border-sm me-2 text-secondary fs-7 mt-1" role="status"></div>}

        { reportError
            && <div className="text-danger fw-bold">{"Report Error: " + reportError}<br /><br /></div>}

        { reportData && (!reportData.data || reportData.data.length === 0)
            && <div className="text-danger my-1">No Report available<br /></div>}

        { reportData && reportData.data && reportData.data.length > 0 && <div className="text-black">

            {/* <div>length: {reportData.data.length}</div>
            <div>rows: {reportData.rows}</div>
            <div>pageNo: {reportData.page}</div>
            <div>itemsPerPage: {reportData.itemsPerPage}</div> */}
            {/* <div>{JSON.stringify(reportData)}</div> */}


            {/* ===== TITLE: DETAILS, ESTATE, QUERYDATE ===== */}
            <div className="mb-3">
                <b>Completed Inference Reports</b>
            </div>


            {/* ===== BUTTONS: RELOAD, EXPORT ===== */}
            <div className="d-flex align-items-baseline mb-1 mt-3">
                <button type="button" className="btn btn-info btn-sm py-0"
                    onClick={(e) => setreportVersion(reportVersion + 1)}>Reload <i className="bi bi-arrow-repeat"></i></button>
                <button type="button" className="btn btn-info btn-sm py-0 ms-2"
                    onClick={(e) => handleExportCSV()}>Export <i className="bi bi-cloud-download"></i></button>
            </div>



            {/* ===== LISTING ===== */}
            <SortableTable tableData={reportData.data}
                onSort={handleSortedData} //this is the callback after sort done inside sortableTable
                colList={colList}
                colListFull={colList}
                isFullDetails={true}
                handlerColHeaderRename={getInferReportHeaderName} // from ColFormatInferReport.js
                handlerColHeaderAlign={getInferReportHeaderAlignment} // from ColFormatInferReport.js
                handlerColFormatValue={getInferReportFormatValue} // from ColFormatInferReport.js
                // dict of columns to totally reformat (ie imgName => <a href>XXXXX_</a>)
                handlerColReformatMap={{ "Report": handlerInferReportColQueryDate }} />


            {/* ===== LISTING ===== */}
            {/* <table className="table table-hover w-auto table-condensed mb-0 fs-p13" align="start">
                <thead>
                    <tr>
                        <th style={{...cellStyle, paddingLeft: "0px"}} className="text-start">Date</th>
                        <th style={cellStyle} className="text-center">Estate</th>
                        <th style={cellStyle} className="text-center">Records</th>
                        <th style={cellStyle} className="text-center">Images</th>
                    </tr>
                </thead>
                <tbody>
                    {reportData.data.map((report, jobIdx) => (
                        <tr key={jobIdx}>
                            <td style={{...cellStyle, paddingLeft: "0px"}}>
                                <a href={"/app/sonic/infer/listbyimg?estate=" + report.estate + "&date=" + report.date}>{formatDateString(report.date)}</a>
                            </td>
                            <td style={cellStyle} className="text-center">{report.estate}</td>
                            <td style={cellStyle} className="text-end">{formatNumberComma(report.count_records)}</td>
                            <td style={cellStyle} className="text-end">{formatNumberComma(report.count_fruit)}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}


            {/* ===== PAGINATION ===== */}
            {/* <br />
            <PaginationComponent
                totalRows={reportData.rows}
                currentPageNo={reportData.page}
                itemsPerPage={reportData.itemsPerPage}
                onPageChange={handlePageChange}
            /> */}

        </div>}

    </>);
}
export default SonicInferReport;
// import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/NavBar";
import { initConfig } from "./Config";
import PlutoIndex from "./pluto/PlutoIndex";
import MainIndex from "./main/MainIndex";
import SonicViewImage from "./sonic/SonicImageView";
import SonicInferByImage from "./sonic/SonicInferByImage";
import SonicInferReport from "./sonic/SonicInferReport";
import SonicJobs from "./sonic/SonicJobs";
import { useState } from "react";
import Login from "./components/Login";
import SonicInferByTph from "./sonic/SonicInferByTph";

// NPM installs
// npm install bootstrap
// npm install sass
// npm install react-router-dom
// npm install preval.macro ## for getting react build time
// npm install react-secure-storage (for secure localStorage)
// npm i bootstrap-icons
// npm install react-bootstrap ## for overlaytrigger etc
// npm install react-helmet ## for adding style tag to head
// npm install abortcontroller-polyfill ## for RESTQuery, to support older browsers (like samsung tv)

// versioning bump in package.json (newscentre)
// npm version major --no-git-tag-version
// npm version minor --no-git-tag-version
// npm version patch --no-git-tag-version

// initialise env configs in global Config.js
initConfig();

function App()
{
	// ===== PASSWORD VALIDATION: TEMPORARY FAKE LOGIN =====
	// To check if the password has been validated on subsequent pages
	const [isPasswordValidated, setIsPasswordValidated] = useState(sessionStorage.getItem('passwordValidated') === 'true');
	const handlePasswordValidated = (isValid) =>
	{
		setIsPasswordValidated(isValid);
	};

	{/* PASSWORD */}
	if (!isPasswordValidated)
	{
		return (<Login onPasswordValidated={handlePasswordValidated} />);
	}
	else
		return (<div>
		<Router>

			<NavBar />

			<Routes>

				<Route path="/" element={<MainIndex />} />

				{/* ===== SONIC ===== */}
				{/* if non specified, go straighted to report */}
				<Route path="/app/sonic" element={<SonicInferReport />} />
				<Route path="/app/sonic/inference/viewimg" element={<SonicViewImage />} />

				<Route path="/app/sonic/infer/listbyimg" element={<SonicInferByImage />} />
				<Route path="/app/sonic/infer/listbytph" element={<SonicInferByTph />} />
				<Route path="/app/sonic/infer/listreport" element={<SonicInferReport />} />
				<Route path="/app/sonic/infer/explorer" element={<SonicInferReport />} />
				<Route path="/app/sonic/infer/listjobs" element={<SonicJobs />} />
				<Route path="/app/sonic/infer/listflags" element={<SonicInferByImage isFlagQuery={true} />} />


				{/* ===== PLUTO ===== */}
				<Route path="/app/pluto" element={<PlutoIndex />} />

			</Routes>

		</Router>
	</div>);
}

export default App;

// helper class to maintain consistent output and naming
// for INFERENCEFRUIT
// 1. rename of column headers
// 2. formatting of column values
// 3. visual logic via bootstrap classnames for columns

import { formatDateString, formatNumberComma, parseISODateToDDMMMYYHHMMSS } from "../../components/Utils";

// "date": "20240828",
// "estate": "CMA",
// "count_records": 986,
// "count_fruit": 1061
export const getInferReportHeaderName = (headerName) =>
{
    if (headerName === "date")
        return "Date";
    else if (headerName === "estate")
        return "Estate";
    else if (headerName === "count_records")
        return "Records";
    else if (headerName === "count_fruit")
        return "Images";
    else if (headerName === "meanTotalBunches")
        return <>mean<br/>totalBunches</>;
    else if (headerName === "meanMainCount")
        return <>mean<br/>computed</>;
    else if (headerName === "meanConfidence")
        return <>mean<br/>confidence</>;
    else if (headerName === "endTime")
        return "Processed Time";


    return headerName;
}

export const getInferReportHeaderAlignment = (headerName) =>
{
    var alignText = "text-center";
    // first column is always aligned left/start
    // changed from date to Report
    // if (headerName === "date")
    if (headerName === "Report")
        alignText = "text-start";

    return alignText;
}

{/*
<td style={cellStyle} className="text-center">{report.estate}</td>
<td style={cellStyle} className="text-end">{formatNumberComma(report.count_records)}</td>
<td style={cellStyle} className="text-end">{formatNumberComma(report.count_fruit)}</td> */}

// works with ACTUAL column name. do NOT use adjusted colName eg block, inferDate etc. use harvestPBlock, inferenceDate etc
// item is dataset, cos some columns are colored based off the values of another column (eg main_count relies on error%)
// textAlign: default to text-center, but can override
export const getInferReportFormatValue = (colName, aValue, item, textAlign="text-center") =>
{
    var value = aValue;
    var className = "";

    if (!aValue)
        return [value, className];

    // numbers count_records and count_fruit align right/end
    if (colName === "count_records" || colName === "count_fruit")
    {
        value = formatNumberComma(aValue);
        textAlign = "text-end";
    }
    else if (colName === "startTime" || colName === "endTime")
    {
        // value = parseISODateString(aValue);
        value = parseISODateToDDMMMYYHHMMSS(aValue);
        // to make sure no line breaks unnecessarily if width is too short
        // check SonicInferByImage.handlerColDate() where it deals with text-nowrap
    }
    else if (colName === "meanTotalBunches" || colName === "meanMainCount")
        value = aValue ? aValue.toFixed(1) : "";
    else if (colName === "inferenceDuration")
        value = aValue ? aValue.toFixed(4) : "";
    else if (colName === "meanConfidence")
    {
        value = (aValue ? 100 * aValue : 0).toFixed(1); // 0 should also be formatted here to be neat

        // grey out 0 confidence
        if (value === 0) className += "text-secondary text-opacity-75";
    }


    // combine className with textAlign
    className = textAlign + " " + className;
    return [value, className];
}



// deals specifically with displaying the column "date" to hyperlink to the report, called inside SortableTable
export const handlerInferReportColQueryDate = (aValue, dataIdx, data, isFullDetails) =>
{
    return (<>
        <a href={"/app/sonic/infer/listbyimg?estate=" + data.estate + "&date=" + data.date}>
            Img
        </a>
        <a href={"/app/sonic/infer/listbytph?estate=" + data.estate + "&date=" + data.date}
            className="ms-2">
            Tph
        </a>
        &nbsp;
    </>);
    // return (<>
    //     <a href={"/app/sonic/infer/listbyimg?estate=" + data.estate + "&date=" + data.date}>
    //         {formatDateString(data.date)}
    //     </a>
    //     <a href={"/app/sonic/infer/listbytph?estate=" + data.estate + "&date=" + data.date}
    //         className="ms-2">
    //         TPH
    //     </a>
    // </>);
}


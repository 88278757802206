// versioning bump in package.json (newscentre)
// npm version major --no-git-tag-version
// npm version minor --no-git-tag-version
// npm version patch --no-git-tag-version

function MainIndex({})
{

    return (<>
        <h3 className="mt-2 mb-2 fw-bold">Welcome</h3>

        <p className="mt-3">This is a development instance of Project Batik.</p>

        <p>All debugging and testing will be conducted here. Please note that all data and results are subject to checks, validations and changes - and may contain inaccuracies. As this is an early-stage release, you may encounter bugs and missing features. We aim to progressively upgrade this development instance. Change logs will be updated accordingly below for easy reference.</p>

        <p>This deployment is currently running on AWS EC2 t2.micro (Singapore region) with MongoDB Atlas M0. Note that this is a small cloud instance, which may result in limited dataset capacity and slower system performance. Production deployment will feature significantly enhanced specifications.</p>

        <p className="fw-bold text-danger">Important Notes</p>
        <ul>
            <li><b>No production systems are currently linked.</b><br />This will change over the next few weeks as parallel efforts by the different teams come together that will enable us to integrate the systems and data.</li>
            <li className="mt-2"><b>All processing is performed on an ad-hoc basis.</b><br />Processing have been conducted on a small subset of estates. In production, these processes will be seamlessly integrated, with up-to-date data computed daily in the background.</li>
        </ul>
        <p> </p>

        <p>We value your feedback and suggestions.</p>

        <p>Please reach out to us at <a href="https://www.sempoa.ai/contact">admin@sempoa.ai</a> or contact your nearest friendly Sempoa AI representative.</p>


        {/* ===== SONIC RELEASE NOTES ===== */}
        <h4 className="text-purple-500 fw-bold mt-4"><a href="/app/sonic" style={{ color: '#6b46c1', textDecoration: "None" }}>Sonic</a></h4>
        <div className="fs-p14">

            <div className="fs-6"><b>v1.3.0 alpha</b>, 17 Sep 2024</div>
            <div>- added Flagged tab to list all flagged images by userName or for all users</div>
            <div>- Report: added ability to flag images that can be viewed separately in Flagged tab</div>
            <br />

            <div className="fs-6"><b>v1.2.0 alpha</b>, 16 Sep 2024</div>
            <div>- InferenceReport: added sortableTable</div>
            <div>- Fixed sortableTable headings to sort in sequence of ascending, descending, original, then back to ascending etc</div>
            <div>- Report: added reporting by image or by TPH, added additional metadata details like distance image was taken to TPH, distance record was added to TPH, distance image was taken to record</div>
            <div>- ReportByTPH: view summary of inference, results of each image within TPH (for multiple images per TPH) are summed/averaged</div>
            <br />

            <div className="fs-6"><b>v1.1.0 alpha</b>, 14 Sep 2024</div>
            <div>- InferenceProcessing: added mean confidence per image and per job</div>
            <div>- Jobs: mean confidence (actual) and group mean confidence (ie mean of mean) are computed and displayed</div>
            <div>- ImageView: added mean confidence of detected fruits (annotations), plus additional info like inference date/duration, image width/height/orientation (for debugging)</div>
            <div>- ImageView: added listing and selection of individual annotations (fruit), and buttons to show all/none at once. individual listings are hidden by default until the link "show" is clicked.</div>
            <br />

            <div className="fs-6"><b>v1.0.0 alpha</b>, 13 Sep 2024</div>
            <div>- First alpha release</div>
        </div>
        <p></p>


        {/* ===== PLUTO RELEASE NOTES ===== */}
        <h4 className="text-purple-500 fw-bold mt-4"><a href="/app/pluto" style={{ color: '#6b46c1', textDecoration: "None" }}>Pluto</a></h4>
        <div className="fs-p14">
            <div className="fst-italic fs-6">Pluto Alpha release v1.0.0 is expected on 11 Oct 2024</div>
        </div>

        <br />
    </>);
}
export default MainIndex;
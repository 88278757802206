// helper class to maintain consistent output and naming
// for INFERENCEFRUIT
// 1. rename of column headers
// 2. formatting of column values
// 3. visual logic via bootstrap classnames for columns

import { parseISODateToDDMMMYYHHMMSS } from "../../components/Utils";


export const getJobsHeaderName = (headerName) =>
{
    if (headerName === "durationSeconds")
        return <>(secs)<br/>duration</>;
    else if (headerName === "meanTotalBunches")
        return <>mean<br/>totalBunches</>;
    else if (headerName === "medianTotalBunches")
        return <>median<br/>totalBunches</>;
    else if (headerName === "meanMainCount")
        return <>mean<br/>computed</>;
    else if (headerName === "medianMainCount")
        return <>median<br/>computed</>;
    else if (headerName === "meanTimePerImage")
        return <>meanTime<br/><span className="text-nowrap">per image</span></>;
    else if (headerName === "queryDate")
        return <>date</>;
    else if (headerName === "meanConfidence")
        return <>mean confidence</>;
    else if (headerName === "meanConfidenceAvg")
        return <><span className="text-nowrap">group mean</span><br/>confidence</>;
    else if (headerName === "count")
        return <>count<br/>images</>;

    return headerName;
}

export const getJobsHeaderAlignment = (headerName) =>
{
    var alignText = "text-center";
    if (headerName === "type")
        alignText = "text-start";

    return alignText;
}

// works with ACTUAL column name. do NOT use adjusted colName eg block, inferDate etc. use harvestPBlock, inferenceDate etc
// item is dataset, cos some columns are colored based off the values of another column (eg main_count relies on error%)
// textAlign: default to text-center, but can override
export const getJobsFormatValue = (colName, aValue, item, textAlign="text-center") =>
{
    var value = aValue;
    var className = "";

    if (!aValue)
        return [value, className];

    if (colName === "type" && aValue)
    {
        if (aValue === "inferenceFruit")
            value = "fruit";
        else if (aValue === "inferenceRecapture")
            value ="recapture";
    }
    else if (colName === "startTime" || colName === "endTime")
    {
        // value = parseISODateString(aValue);
        value = parseISODateToDDMMMYYHHMMSS(aValue);
        // to make sure no line breaks unnecessarily if width is too short
        // check SonicInferByImage.handlerColDate() where it deals with text-nowrap
    }
    else if (colName === "description")
    {
        // replace ", " with <br> in aValue
        // value = <>{aValue.replace(/, /g, <br/>)}</>; // THIS DOENST WORK since it is being interpreted as object, so no <br/>
        value = <span className="text-nowrap" dangerouslySetInnerHTML={{ __html: aValue.replace(/, /g, "<br/>") }} />;
    }
    else if (colName === "meanTotalBunches" || colName === "meanMainCount")
        value = aValue ? aValue.toFixed(1) : "";
    else if (colName === "durationSeconds")
        value = aValue ? aValue.toFixed(1) : "";
    else if (colName === "meanTimePerImage")
        value = aValue ? aValue.toFixed(4) : "";
    else if (colName === "meanConfidence" || colName === "meanConfidenceAvg")
    {
        value = (aValue ? 100 * aValue : 0).toFixed(1); // 0 should also be formatted here to be neat
        // grey out 0 confidence
        if (value === 0) className += "text-secondary text-opacity-75";
    }
    // else if (colName === "count")
    //     value = aValue ? formatNumberComma(aValue) : 0

    // combine className with textAlign
    className = textAlign + " " + className;

	// returns value, className
	return [value, className];




    // <td style={cellStyle} className="text-center">{job.durationSeconds ? job.durationSeconds.toFixed(1) : ""}</td>
    // <td style={cellStyle} className="text-center">{job.count}</td>
    // <td style={cellStyle} className="text-center">{job.meanTotalBunches.toFixed(1)}</td>
    // <td style={cellStyle} className="text-center">{job.meanMainCount.toFixed(1)}</td>
    // <td style={cellStyle} className="text-center">{job.medianTotalBunches}</td>
    // <td style={cellStyle} className="text-center">{job.medianMainCount}</td>
    // <td style={cellStyle} className="text-center">{job.avgTimePerImage.toFixed(4)}</td>
    // {isFullDetails && <>
    //     <td style={cellStyle} className="text-center">{job.userName}</td>
    //     <td style={cellStyle} className="text-center">{job.hostName}</td>
    //     <td style={cellStyle} className="text-center">{job.ipAddress}</td>
    // </>}
}
// includeStatsLine: if true, displays a line with stats of total rows, pages, etc at the bottom
const PaginationComponent = ({ totalRows, currentPageNo, itemsPerPage, onPageChange, includeStatsLine=true }) =>
{
    const totalPages = (!itemsPerPage || itemsPerPage === 0) ? 1 : Math.ceil(totalRows / itemsPerPage);

    const getPaginationItems = (startVisiblePages = 3, endVisiblePages = 3) =>
    {
        let items = [];
        const startPage = Math.max(1, currentPageNo - 1);
        const endPage = Math.min(totalPages, currentPageNo + 1);

        // Add start pages
        for (let i = 1; i <= Math.min(startVisiblePages, totalPages); i++) {
            items.push(
                <li key={i} className={`page-item ${i === currentPageNo ? 'active' : ''}`}>
                    <a className="page-link" href="#" onClick={(e) => {
                        e.preventDefault();
                        onPageChange(i);
                    }}>{i}</a>
                </li>
            );
        }

        // Add ellipsis if needed
        if (startPage > startVisiblePages + 1) {
            items.push(
                <li key="ellipsis-start" className="page-item disabled">
                    <span className="page-link">...</span>
                </li>
            );
        }

        // Add middle pages
        for (let i = Math.max(startVisiblePages + 1, startPage); i <= Math.min(totalPages - endVisiblePages, endPage); i++) {
            items.push(
                <li key={i} className={`page-item ${i === currentPageNo ? 'active' : ''}`}>
                    <a className="page-link" href="#" onClick={(e) => {
                        e.preventDefault();
                        onPageChange(i);
                    }}>{i}</a>
                </li>
            );
        }

        // Add ellipsis if needed
        if (endPage < totalPages - endVisiblePages) {
            items.push(
                <li key="ellipsis-end" className="page-item disabled">
                    <span className="page-link">...</span>
                </li>
            );
        }

        // Add end pages
        for (let i = Math.max(totalPages - endVisiblePages + 1, endPage + 1); i <= totalPages; i++) {
            items.push(
                <li key={i} className={`page-item ${i === currentPageNo ? 'active' : ''}`}>
                    <a className="page-link" href="#" onClick={(e) => {
                        e.preventDefault();
                        onPageChange(i);
                    }}>{i}</a>
                </li>
            );
        }

        return items;
    };

    return (<div>
        <nav aria-label="Page navigation">
            <ul className="pagination pagination-sm">
                <li className={`page-item ${currentPageNo === 1 ? 'disabled' : ''}`}>
                    <a className="page-link" href="#" onClick={(e) => {
                        e.preventDefault();
                        onPageChange(currentPageNo - 1);
                    }}>Previous</a>
                </li>
                {getPaginationItems()}
                <li className={`page-item ${currentPageNo === totalPages ? 'disabled' : ''}`}>
                    <a className="page-link" href="#" onClick={(e) => {
                        e.preventDefault();
                        onPageChange(currentPageNo + 1);
                    }}>Next</a>
                </li>
            </ul>
        </nav>

        {/* ===== STATS LINE ===== */}
        {includeStatsLine &&
            <div className="fs-p12 text-secondary fst-italic">
                Total rows: {totalRows}
                {totalPages && <>, pages: {totalPages}</>}
                {itemsPerPage && <>, rowsPerPage: {itemsPerPage}</>}
            </div>}
    </div>);
};
export default PaginationComponent;

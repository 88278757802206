// helper class to maintain consistent output and naming
// for INFERENCEFRUIT
// 1. rename of column headers
// 2. formatting of column values
// 3. visual logic via bootstrap classnames for columns

import { formatIntegerWithSign, parseISODateToDDMMMYYHHMMSS } from "../../components/Utils";

export const getTphHeaderName = (headerName) =>
{
    if (headerName === "blockPlatform")
        return "block";
    else if (headerName === "harvestBlock")
        return "block";
    else if (headerName === "harvestPlatformName")
        return "platform";
    // else if (headerName === "inferenceDate")
    //     return "inference date";
    // else if (headerName === "inferenceDuration")
    //     return "inference duration";
    else if (headerName === "mechanizationFlag")
        return "mechanization";
    else if (headerName === "mainCount")
        return "computed";
    else if (headerName === "harvestAfd")
        return "afdeling";
    // else if (headerName === "meanConfidence")
    //     return "mean confidence";
    else if (headerName === "isLandscape")
        return "orientation";
    else if (headerName === "dImg2Record")
        return "img2Record (m)";
    else if (headerName === "dImg2Tph")
        return "img2Tph (m)";
    else if (headerName === "dRecord2Tph")
        return "record2Tph (m)";
    else if (headerName === "dTph2Tph")
        return "tph2Tph (m)";

    return headerName;
}


// ALIGNMENT FOR HEADER
export const getTphHeaderAlignment = (headerName) =>
{
    var alignText = "text-center";
    if (headerName === "idTPanen")
        alignText = "text-start";

    return alignText;
}


// works with ACTUAL column name. do NOT use adjusted colName eg block, inferDate etc. use harvestPBlock, inferenceDate etc
// item is dataset, cos some columns are colored based off the values of another column (eg main_count relies on error%)
// textAlign: default to text-center, but can override
// RETURNS: [value, className]
export const getTphFormatValue = (colName, aValue, item, textAlign="text-center") =>
{
    // TODO: HANDLE invalid aValue, if null/blank values, can throw errors!!!

    var value = aValue;
    var className = "";


    // if (!aValue)
    //     return [value, className];

    if (colName === "mainCount" && item.hasOwnProperty("diff"))
    {
        const diff = item["diff"];
        if (diff > 10) className += " bg-danger bg-opacity-25";
        else if (diff > 0) className += " bg-warning bg-opacity-25";
    }
    else if (colName === "error%")
    {
        const errorPct = item["error%"];
        if (errorPct > 25) className += " text-danger";
        else if (errorPct > 0) className += " text-orange-600";
        else className += " text-success"

        // value = aValue.toFixed(1);
        value = aValue;
    }
    else if (colName === "diff")
    {
        const diff = item["diff"];
        if (diff > 0) className += " text-blue-500";
        else if (diff < 0) className += " text-red-500";
        else className += " text-success";

        value = formatIntegerWithSign(aValue);
    }
    else if (colName === "createDate" || colName === "inferenceDate")
    {
        // value = parseISODateString(aValue);
        if (aValue)
            value = parseISODateToDDMMMYYHHMMSS(aValue);
        // to make sure no line breaks unnecessarily if width is too short
        // check SonicInferByImage.handlerColDate() where it deals with text-nowrap
    }
    else if (colName === "imgLocation" || colName === "imgName")
        textAlign = "text-start"
    else if (colName === "inferenceDuration")
        value = aValue ? aValue.toFixed(4) : "";
    else if (colName === "meanConfidence")
    {
        value = (aValue ? 100 * aValue : 0).toFixed(1); // 0 should also be formatted here to be neat

        // grey out 0 confidence
        if (value === 0) className += "text-secondary text-opacity-75";
    }
    else if (colName === "isLandscape")
        value = aValue ? "Landscape" : "Portrait";
    else if (colName === "dImg2Tph" || colName === "dImg2Record")
        value = aValue ? aValue.toFixed(1) : "";
    else if (colName === "inferenceCount" && aValue === 0)
        className += "bg-warning"
    else if (colName === "harvestBlock")
        className = "text-nowrap";

    // combine className with textAlign
    className = textAlign + " " + className;

	return [value, className];
}


// deals specifically with displaying the column for imgName, called inside SortableTable
// have to include dataIdx, data as well cos that's the INTEFACE USED and called in SortableTable
export const handlerTphColDate = (aValue, dataIdx, data, isFullDetails) =>
{
    if (!aValue) return;
    //const a = "27 Aug 2024 14:19:10";
    // Split the string into its components
    const [day, month, year, time] = aValue.split(' ');

    // Combine the date parts
    const date = <span className="text-nowrap">{day} {month} {year}</span>;

    return (<span className={isFullDetails ? "text-nowrap" : ""}>{date}{isFullDetails ? <br/> : " "}{time}</span>);
}


import React, { useEffect, useRef, useState } from "react";
import { getViewportWidthSize, getViewportWidthType } from "../Config";
import { cellStyle, cellStyleTight } from "../components/Utils";
import { getTphFormatValue, getTphHeaderName } from "./helper/ColFormatInferTph";
import SonicImgViewSingle from "./SonicImgViewSingle";

// columns to display as INFO
const infoColList = ["createDate", "totalBunches", "mainCount", "diff", "error%", "harvesterCount", "imgCount",
    // "imgID",
    "dRecord2Tph",
    "dTph2Tph",
    "estate", "estCode", "harvestAfd", "harvestBlock", "harvestPlatformName", "createBy", "mechanizationFlag", "idTPanen",
    "inferenceCount"
];
// columns to display as INFERANCE STATS
// const inferColList = ["meanConfidence", "inferenceDuration", "inferenceDate", "width", "height", "isLandscape"];

const infoColHalf = Math.ceil(infoColList.length / 2);
const infoColList1 = infoColList.slice(0, infoColHalf);
const infoColList2 = infoColList.slice(infoColHalf);


const imgColList = [
    "mainCount",
    "totalBunches",
    "diff",
    "error%",
    "meanConfidence",
    "dImg2Tph", "dImg2Record",
    "height", "width",
    ] //"isLandscape", ];

const inferClassName = "bg-secondary bg-opacity-10 border-secondary border-opacity-25";

export const cellInferStyle =
{
    paddingTop: '1px',
    paddingBottom: '1px',
    paddingLeft: '5px',
    paddingRight: '5px'
};

// THIS IS A MODIFIED VERSION OF SonicImageView
// - difference is this is viewed by TPH, and each TPH might have multiple images
// - so the data structure is quite different. the imgLocation is stored in imgData
// which contains nested details
//
// data: this is from imgData[X]
// setPageType: call back function for "going back" by setting to "list"
// inferFruitData: the entire rows for inference output, so that can go back/fwd (list form?)
function SonicImgViewByTPH({imgData, setPageType, rowIndex, inferFruitData})
{
    // check page width, to determine whether to display 1 column table, or 2 column
    // when widthType==xs, then will be 1 column only table
    const [widthType, setWidthType] = useState(getViewportWidthType());
    const [widthSize, setWidthSize] = useState(getViewportWidthSize());
    const notify = function()
    {
        setWidthType(getViewportWidthType());
        setWidthSize(getViewportWidthSize());
        // console.log("width: " + Math.max(document.documentElement.clientWidth, window.innerWidth));
    }
    window.addEventListener("resize", notify)


    const totalRows = inferFruitData.length;
    // console.log(inferFruitData)
	// const searchParams = useSearchParams();
    // // if imgLocation or data are defined in constructor call, then use that. if not, fall back to url params
    // imgLocation = imgLocation || searchParams.get("imgLocation");
    // data = data || searchParams.get("data");

    const [viewRowIndex, setViewRowIndex] = useState(rowIndex);
    const [viewImgData, setViewImgData] = useState(imgData);
    // whenever viewRowIndex changes, change the viewImgLocation
    useEffect(() =>
    {
        setViewImgData(inferFruitData[viewRowIndex]);
    }, [viewRowIndex]);


    // SHOW INFERENCE DETAILS // used by checkbox
    const [showInferDetails, setShowInferDetails] = useState(true);

    const imgCount = viewImgData.imgCount;


    // FOR QUICKLY GOING TO THE RELEVANT IMGID SECTION
    // AND PROVIDING OFFSET SPECIFIC TO NAVBAR AT TOP
    const linkRef = useRef(null);
    const handleGoToClick = (e, colId) => {
        e.preventDefault();
        const targetId = `${colId + 1}`;
        const targetElement = document.getElementById(targetId);
        const offset = 50; // Adjust this value to match your navbar height
        if (targetElement)
        {
            const elementPosition = targetElement.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.scrollY - offset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };


    return (<div>

        {/* <div>{JSON.stringify(viewImgData)}</div> */}

        {/* ===== BUTTON: BACK TO LIST ===== */}
        <div className="d-flex align-items-center mb-3 mt-2">
            <button type="button" className="btn btn-info btn-sm py-0"
                onClick={(e) =>
                {
                    e.preventDefault();
                    setPageType("list");
                }}><i className="bi bi-arrow-left-circle"></i> Listings</button>

            <span className="form-checkx ms-4 fs-p13">
                <input className="form-check-input" type="checkbox" value="" id="checkShowInferData"
                            checked={showInferDetails} onChange={() => setShowInferDetails(!showInferDetails)}/>
                <label className="form-check-label ms-2" htmlFor="checkShowInferData">Info</label>
            </span>
        </div>


        {/* ===== BUTTON: PREV + NEXT ===== */}
        {/* SHOW PREV + NEXT. first check what line the fileName is at to see if there is a next/prev */}
        <div className="mb-3 d-flex align-items-center">
            {/* <button type="button" className={"btn btn-secondary btn-sm py-0 px-1 fs-9"} disabled={rowIndex <= 0 ? true : false} onClick={() => router.push("?p=details&name=" + name + "&id=" + pageId + "&filename=" + summaryData[rowIndex - 1]["filename"]) }>Prev</button> */}
            <button type="button" className={"btn btn-secondary btn-smX py-0 px-2"} disabled={viewRowIndex <= 0 ? true : false} onClick={(e) => { e.preventDefault(); setViewRowIndex(viewRowIndex - 1);} }><i className="bi bi-arrow-left-circle me-2"></i>Prev</button>
            <span className="mx-2">{viewRowIndex + 1} of {totalRows}</span>
            {/* <button type="button" className="btn btn-secondary btn-sm py-0 px-1 fs-9" disabled={rowIndex >= totalRows - 1 ? true : false} onClick={() => router.push("?p=details&name=" + name + "&id=" + pageId + "&filename=" + summaryData[rowIndex + 1]["filename"])}>Next</button> */}
            <button type="button" className="btn btn-secondary btn-smX py-0 px-2" disabled={viewRowIndex >= totalRows - 1 ? true : false} onClick={(e) => { e.preventDefault(); setViewRowIndex(viewRowIndex + 1) }}>Next<i className="ms-2 bi bi-arrow-right-circle"></i></button>
        </div>


        {/* ===== TABLE DATA: RECORD FIELDS ===== */}
        <div className="d-flex mb-3">
            <table className="table small w-100x w-auto table-condensed table-hover mb-0" align="left">
                <tbody>
                    {/* ===== RECORDS DATA ===== */}
                    {widthType === "xs" && infoColList.map((colName, idx) => {
                        return (<tr key={idx}>{displayColValue(colName, viewRowIndex, inferFruitData)}</tr>); })}
                    {widthType !== "xs" && <>
                        {Array.from({ length: Math.max(infoColList1.length, infoColList2.length) }).map((_, idx) => (
                        <tr key={idx}>
                            {infoColList1[idx] ? displayColValue(infoColList1[idx], viewRowIndex, inferFruitData) : null}
                            {/* spacing */}
                            <td></td>
                            {infoColList2[idx] ? displayColValue(infoColList2[idx], viewRowIndex, inferFruitData) : null}
                        </tr>))}
                    </>}
                    {/* ===== INFERENCE DATA ===== */}
                    {/* {showInferDetails && widthType === "xs" && inferColList.map((colName, idx) => {
                        return (<tr key={idx} className="bg-secondary bg-opacity-10 border-secondary border-opacity-25">{displayColValue(colName, viewRowIndex, inferFruitData, "bg-secondary bg-opacity-10")}</tr>); })}
                    {showInferDetails && widthType !== "xs" && <>
                        {Array.from({ length: Math.max(inferColList1.length, inferColList2.length) }).map((_, idx) => (
                        <tr key={idx}>
                            {inferColList1[idx] ? displayColValue(inferColList1[idx], viewRowIndex, inferFruitData, "bg-secondary bg-opacity-10 border-secondary border-opacity-25") : null}
                            <td className="bg-secondary bg-opacity-10 border-secondary border-opacity-25"></td>
                            {inferColList2[idx] ? displayColValue(inferColList2[idx], viewRowIndex, inferFruitData, "bg-secondary bg-opacity-10 border-secondary border-opacity-25") : null}
                        </tr>))}
                    </>} */}
                </tbody>
            </table>
        </div>


        {/* ===== TABLE DATA: ALL IMAGES INFERENCE DETAILS (TRANSPOSED, imgID as columns) ===== */}
        <div className={"d-flex mb-3 " + (showInferDetails && (viewImgData && "inferenceCount" in viewImgData && viewImgData["inferenceCount"] > 0) ? "" : "d-none")}>
            <table className="table small w-100x w-auto table-condensed table-hover mb-0 fs-p13x" align="left">
                <thead><tr>
                    <th style={cellInferStyle} className={inferClassName}>Images</th>
                    {Array.from({ length: imgCount }).map((_, colId) =>
                        <React.Fragment key={colId}>
                            <th style={cellInferStyle} className={inferClassName}></th>
                            <th style={cellInferStyle} className={"text-end " + inferClassName}>
                                <a href={"#" + (colId + 1)} style={{textDecoration: "None"}}
                                    onClick={e => handleGoToClick(e, colId)} ref={linkRef}>img{colId + 1}</a>
                            </th>
                        </React.Fragment>
                    )}
                    <th style={{...cellInferStyle, "paddingLeft": "10px", "paddingRight": "10px"}} className={inferClassName}>Total</th>
                </tr></thead>
                <tbody>
                    {/* rows based on key of the first item in imgData["1"] */}
                    {/* { viewImgData && viewImgData.imgData && viewImgData.imgData["1"]
                        && Object.keys(viewImgData.imgData["1"]).map((key, idx) => */}
                    { viewImgData && imgColList.map((colName, idx) =>
                        <tr key={idx}>
                            <td style={cellInferStyle} className={inferClassName}>{getTphHeaderName(colName)}</td>
                            {/* imgDataKey: id of imgID */}
                            {/* append an extra "sum" to the end of this list! */}
                            { Object.keys(viewImgData.imgData).map((imgDataKey, idx2) =>
                            {
                                var value, className;
                                if (colName === "totalBunches" || colName === "error%" || colName === "diff")
                                {
                                    value = "";
                                    className = "";
                                }
                                else
                                {
                                    // console.log("colName: " + imgDataKey)
                                    const imgDataRow = (imgDataKey in viewImgData.imgData) ? viewImgData.imgData[imgDataKey] : null;
                                    if (imgDataRow)
                                        [value, className] = getTphFormatValue(colName, imgDataRow[colName], imgDataRow);
                                }
                                return(
                                    <React.Fragment key={idx2}>
                                        <td style={cellInferStyle} className={inferClassName}></td>
                                        <td style={cellInferStyle} className={"text-center " + inferClassName}>{value}</td>
                                    </React.Fragment>
                                );
                            })}
                            {(() =>
                            {
                                const [rowValue, rowClassName] = getTphFormatValue(colName, viewImgData[colName], viewImgData);
                                return (
                                    <td style={{...cellInferStyle, "paddingLeft": "10px", "paddingRight": "10px"}}
                                        className={inferClassName + " " + rowClassName + " text-end"}>
                                        {rowValue}
                                    </td>
                                );
                            })()}

                        </tr>
                    )}

                </tbody>
            </table>
        </div>

        {/* { JSON.stringify(viewImgData.imgData["1"]) } */}

        {/* ===== DISPLAYS IMAGES SPECIFIC FOR THIS TPH ===== */}
        {/* ITERATE THROUGH LIST IN NESTED COLUMN: imgData */}

        { viewImgData && viewImgData.imgData && Object.keys(viewImgData.imgData).map((imgDataKey, idx) =>
        {
            const imgDataRow = viewImgData.imgData[imgDataKey];
            return (
            <div key={idx} className="d-flex align-items-center" id={idx + 1}>
                {/* ===== IMAGE: WITH ANNOTATIONS ===== */}
                <div>
                    {/* <div>{JSON.stringify(imgData)}</div> */}
                    {<SonicImgViewSingle
                        imgData={imgDataRow}
                        imgDataKey={imgDataKey}
                        imgCount={imgCount}
                        imgLocation={imgDataRow.imgLocation}
                        rowIndex={rowIndex}
                        inferFruitData={inferFruitData}
                        widthSize={widthSize}
                        widthType={widthType}
                    />}</div>
            </div>);
        })}


    </div>);
}


// inferFruitData: actual row of report/listing
function displayColValue(colName, rowIndex, inferFruitData, bgColorClassName="")
{
    const value = inferFruitData[rowIndex][colName];
    const [aValue, aClassName] = getTphFormatValue(colName, value, inferFruitData[rowIndex], "text-end");
    const newColName = getTphHeaderName(colName);
    // return <div className={aClassName}>{newColName}: {aValue}</div>
    return <><td style={cellStyle} className={bgColorClassName}>{newColName}</td><td style={cellStyle} className={aClassName + " " + bgColorClassName}>{aValue}</td></>
}

export default SonicImgViewByTPH;

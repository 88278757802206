
// this is by TPH, so data structure must account for nested imgData

import { useCallback, useEffect, useRef, useState } from "react";

const BLUE = "rgba(0, 0, 255, 0.6)";

// this is single image, displays no info apart fm image first. and annotations
// imgDataRow: this is the imgData[id] that contains data.annotations
// indvAnnotationSelectMap: a dict containing rowId => true/false (selected/unselected)
function SonicImage({imgLocation, drawAnnotations, imgDataRow,
                     indvAnnotationSelectMap, includeFruitText=true})
{
    // console.log(imgDataRow)
    // console.log("imgLocation: " + imgLocation);
    // if (!imgLocation || !data) return;

    const imgRef = useRef(null);
    const [imgDimensions, setImgDimensions] = useState({ width: 0, height: 0, left: 0, top: 0 });

    // delegate the annotations so that only populate when image has loaded!
    const [imgAnnotations, setImgAnnotations] = useState();

    const updateDimensions = useCallback(() =>
    {
        if (imgRef.current)
        {
            const rect = imgRef.current.getBoundingClientRect();
            setImgDimensions({
                width: rect.width,
                height: rect.height,
                left: rect.left,
                top: rect.top,
            });
        }
        // console.log("setting imgAnnotations: " + imgDataRow.annotations.length);
        setImgAnnotations(imgDataRow.annotations);

    }, [imgDataRow.annotations]); // must trigger imgDataRow.annotations, or else will be stale fm the first img if img changes (due to callBack behaviour)

    useEffect(() =>
    {
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [updateDimensions]);

    if (!imgLocation)
        return (<div className="text-danger">No IMAGE LOCATION<br/><br/></div>);


    return (<div>
        {/* <div>imgLocation: {imgLocation}</div> */}
        {/* <div>data: {JSON.stringify(data)}</div> */}

        <div style={{ position: 'relative', display: 'inline-block' }}>

        {/* <img src={imgLocation} alt="" style={{maxWidth: 800}}/> */}
        <img ref={imgRef} src={imgLocation} alt="" style={{maxWidth: "100%"}} onLoad={updateDimensions}/>
        <div><a href={imgLocation} className="fs-p12 fst-italic">{imgLocation}</a></div>
        <p></p>

        {/* {drawAnnotations && data.annotations.map((ann, index) => */}
        {drawAnnotations && imgAnnotations && imgAnnotations.map((ann, index) =>
        {
            if (!ann || ann.left === 0) return;

            // only draw if dict[rowId] is TRUE!
            if (!indvAnnotationSelectMap[index]) return;

            const [classId, x_center, y_center, width, height, confidence] = ann.split(' ').map(Number);
            const left = (x_center - width / 2) * imgDimensions.width;
            const top = (y_center - height / 2) * imgDimensions.height;
            const boxWidth = width * imgDimensions.width;
            const boxHeight = height * imgDimensions.height;

            return (
                <div key={index} style={{ position: 'absolute',
                                          border: '1px solid ' + BLUE,
                                          left: `${left}px`,
                                          top: `${top}px`,
                                          width: `${boxWidth}px`,
                                          height: `${boxHeight}px`,
                                          boxSizing: 'border-box',
                                        }}>
                    <div style={{ position: 'absolute',
                                  top: '-14px',
                                  left: '-1px',
                                  backgroundColor: BLUE,
                                  color: 'white',
                                  padding: '1px 2px',
                                  fontSize: '11px',
                                }}>
                        {includeFruitText ? "fruit " : ""}{(100 * confidence).toFixed(0)}
                    </div>
                </div>
            );
        })}

    </div>

    </div>);
}
export default SonicImage;

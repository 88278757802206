// displays list of jobs

import React, { useState } from "react";
// import RestQuery from "../components/RestQuery";
import { HOST, PYTHON_PORT } from "../Config.js";
import useFetch from "../components/useFetch.js";
import { exportToCSV } from "../components/Utils.js";
import PaginationComponent from "../components/PaginationComponent.js";
import SonicTabs from "./SonicTabs.js";
import SortableTable from "../components/SortableTable.js";
import { getJobsFormatValue, getJobsHeaderAlignment, getJobsHeaderName } from "./helper/ColFormatJobs.js";

// to get localhost SSL certificate to work on test computer, need to add SSL of rest server directly to mac keychain and set to always trust. must also ensure cors middleware is setup in rest python code, to allow origin of react: https://m3.local:3000
const jobsUrl = "https://" + HOST + ":" + PYTHON_PORT + "/sonic/jobs";
// jobsColumns = ["type", "estate", "description",	"startTime", "endTime", durationSeconds	duration	count	meanTotalBunches	meanMainCount	medianTotalBunches	medianMainCount	avgTimePerImage	userName	hostName	ipAddress]

const colList = ["type", "estate", "queryDate", "startTime", "durationSeconds", "count", "meanTotalBunches", "meanMainCount", "medianTotalBunches", "medianMainCount", "meanConfidence", "meanConfidenceAvg", "meanTimePerImage"];
const colListFull = ["type", "estate", "queryDate", "description", "startTime", "endTime", "durationSeconds", "count", "meanTotalBunches", "meanMainCount", "medianTotalBunches", "medianMainCount", "meanConfidence", "meanConfidenceAvg", "meanTimePerImage", "userName", "hostName", "ipAddress"];



function SonicJobs()
{
    const [jobsVersion, setJobsVersion] = useState(0);
    const { data: jobsData, isPending: jobsPending, error: jobsError } = useFetch({url: jobsUrl,
        version: jobsVersion, isAuth: false, excludeHeader: true});

    // PAGINATION: NOT USED AT THE MOMENT
    const [currentPageNo, setCurrentPageNo] = useState(1);
    const handlePageChange = (pageNo) =>
    {
        console.log("page change: " + pageNo);
        setCurrentPageNo(pageNo);
    };

    // checkbox to display FULL DETAILS of table
    const [isFullDetails, setFullDetails] = useState(false);
    const handleFullDetailsChange = (event) =>
    {
        setFullDetails(event.target.checked);
    };

    const handleExportCSV = () =>
    {
        // note, we only need to pass it the "data" key
        if (jobsData && jobsData.data)
            exportToCSV(jobsData.data, 'jobs_data.csv');
        else
            console.warn("No data to export")
    };


    // ===== SORTABLE TABLE =====
    // SORTED DATA: callback from SortableTable so that sortedData can be passed to SonicViewImage
    const [sortedData, setSortedData] = useState([]);
    const handleSortedData = (data) =>
    {
        setSortedData(data);
    };


    return (<>

        {/* ===== TABS ===== */}
        <SonicTabs headId={3} />


        { jobsPending
            && <div className="spinner-border spinner-border-sm me-2 text-secondary fs-7 mt-1" role="status"></div>}

        { jobsError
            && <div className="text-danger fw-bold">{"Jobs Error: " + jobsError}<br /><br /></div>}

        { jobsData && (!jobsData.data || jobsData.data.length === 0)
            && <div className="text-danger my-1">No Jobs available<br /></div>}

        { jobsData && jobsData.data && jobsData.data.length > 0 && <div className="text-black">

            {/* <div>length: {jobsData.data.length}</div>
            <div>rows: {jobsData.rows}</div>
            <div>pageNo: {jobsData.page}</div>
            <div>itemsPerPage: {jobsData.itemsPerPage}</div> */}
            {/* <div>{JSON.stringify(jobsData)}</div> */}


            {/* ===== TITLE: DETAILS, ESTATE, QUERYDATE ===== */}
            <div className="mb-3">
                <b>Status of Inference Processing</b>
            </div>


            {/* ===== BUTTONS: RELOAD, EXPORT ===== */}
            <div className="d-flex align-items-baseline mb-1 mt-3">
                <button type="button" className="btn btn-info btn-sm py-0"
                    onClick={(e) => setJobsVersion(jobsVersion + 1)}>Reload <i className="bi bi-arrow-repeat"></i></button>

                <button type="button" className="btn btn-info btn-sm py-0 ms-2"
                    onClick={(e) => handleExportCSV()}>Export <i className="bi bi-cloud-download"></i></button>

                <span className="form-check fs-p14 ms-3">
                    <input className="form-check-input" type="checkbox" value="" id="checkFullDetails"
                                checked={isFullDetails} onChange={handleFullDetailsChange}/>
                    <label className="form-check-label" htmlFor="checkFullDetails">
                        Details
                    </label>
                </span>
            </div>


            {/* ===== JOBS LISTING ===== */}
            <SortableTable tableData={jobsData.data}
                onSort={handleSortedData} //this is the callback after sort done inside sortableTable
                colList={colList}
                colListFull={colListFull}
                isFullDetails={isFullDetails}
                handlerColHeaderRename={getJobsHeaderName} // from ColFormatJobs.js
                handlerColHeaderAlign={getJobsHeaderAlignment} // from ColFormatJobs.js
                handlerColFormatValue={getJobsFormatValue} // from ColFormatJobs.js
                // dict of columns to totally reformat (ie imgName => <a href>XXXXX_</a>)
                handlerColReformatMap={{ "queryDate": handlerColQueryDate,
                                         "startTime": handlerColDate,
                                         "endTime": handlerColDate
                                      }} />


            {/* ===== PAGINATION ===== */}
            <br />
            <PaginationComponent
                totalRows={jobsData.rows}
                currentPageNo={jobsData.page}
                itemsPerPage={jobsData.itemsPerPage}
                onPageChange={handlePageChange}
            />

        </div>}

    </>);

    // deals specifically with displaying the column "date" to hyperlink to the report, called inside SortableTable
    function handlerColQueryDate(aValue, dataIdx, data)
    {
        return (
            <a href={"/app/sonic/infer/listbyimg?estate=" + data.estate + "&date=" + data.queryDate}
                title="Note: Only the latest inference runs are stored. Any jobs that run multiple times (for the same estate and date) would be overidden by the latest run.">{data.queryDate}</a>
        );
    }

    // deals specifically with displaying the column for imgName, called inside SortableTable
    // have to include dataIdx, data as well cos that's the INTEFACE USED and called in SortableTable
    function handlerColDate(aValue, dataIdx, data)
    {
        if (!aValue) return;
        //const a = "27 Aug 2024 14:19:10";
        // Split the string into its components
        const [day, month, year, time] = aValue.split(' ');

        // Combine the date parts
        const date = <span className="text-nowrap">{day} {month} {year}</span>;

        return (<span className={isFullDetails ? "text-nowrap" : ""}>{date}{isFullDetails ? <br/> : " "}{time}</span>);
    }
}
export default SonicJobs;
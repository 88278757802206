

export const parseISODateString = (isoString) =>
{
    // const isoString = "2024-09-10T15:21:23.688000";

    // Parse the ISO string to a Date object
    const date = new Date(isoString);

    // Extract the components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format the date and time
    const formattedDate = `${year}${month}${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const parseISODateToDDMMMYYHHMMSS = (isoString, includeTime=true, includeComma=false) =>
{
    // const isoString = "2024-09-10T15:21:23.688000";

    // Parse the ISO string to a Date object
    const date = new Date(isoString);

    // Extract the components
    const year = date.getFullYear();
    // const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const month = monthNames[date.getMonth()]; // Get month abbreviation
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // get month

    // Format the date and time
    const formattedDate = includeTime ? `${day} ${month} ${year}`
        + (includeComma ? ", " : " ") + `${hours}:${minutes}:${seconds}`
        : `${day} ${month} ${year}`;
    return formattedDate;
}



export const cellStyle =
{
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '4px',
    paddingRight: '4px'
};

export const cellStyleTight =
{
    paddingTop: '1px',
    paddingBottom: '1px',
    paddingLeft: '3px',
    paddingRight: '3px'
};

// dateString: YYYYMMDD
// converts YYYYMMDD to 23 Aug 2024
export const formatDateString = (dateString, includeYear=true) =>
{
    // Check if the date string is valid
    if (!/^\d{8}$/.test(dateString)) return dateString; // Return the original string if it's not a valid date

    // Extract year, month, and day from the string
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    const date = new Date(`${year}-${month}-${day}`);

    // Check if the date is valid
    if (isNaN(date.getTime())) return dateString; // Return the original string if the date is invalid

    // Format the date
    const options = { month: 'short', day: 'numeric' };
    if (includeYear)
        options.year = 'numeric';

    // const options = { year: 'numeric', month: 'short', day: 'numeric' };
    // return date.toLocaleDateString('en-US', options); # this returns: MMM DD, YYYY
    // Use Intl.DateTimeFormat to format the date: DD MMM YYYY
    return new Intl.DateTimeFormat('en-GB', options).format(date);
}

// used by formatNumberFormat()
const NUMBER_FORMAT = new Intl.NumberFormat('en-US')

// returns comma delimited number (0dp)
export const formatNumberComma = (number) =>
{
    return NUMBER_FORMAT.format(number);
}

    // returns +/-value
export const formatIntegerWithSign = (value) =>
{
    if (value > 0)
        return `+${value}`;
    else if (value < 0)
        return `${value}`;
    else
        return '0';
}



export const exportToCSV = (data, filename="export.csv", columnsToIgnore = []) =>
{
    if (!data || data.length === 0)
    {
        console.error("No data to export");
        return;
    }

    const convertToCSV = (objArray) =>
    {
         // Filter out ignored columns from the headers
        const headers = Object.keys(objArray[0]).filter(key => !columnsToIgnore.includes(key));
        // const array = [Object.keys(objArray[0])].concat(objArray);
        const csvRows = [headers];
        // Add data rows
        objArray.forEach(obj =>
        {
            const row = headers.map(header =>
            {
                const value = obj[header];
                return typeof value === 'string' ? JSON.stringify(value) : value;
            });
            csvRows.push(row);
        });
        return csvRows.map(row => row.join(',')).join('\n');
        // return array.map(row =>
        // {
        //     return Object.values(row).map(value =>
        //     {
        //         return typeof value === "string" ? JSON.stringify(value) : value;
        //     }).join(",");
        // }).join("\n");
    };

    const csvContent = convertToCSV(data);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined)
    {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};

import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function PlutoIndex({})
{
    const [headId, setHeadId] = useState(0);
    function handleClickTab(e, myHeadId)
    {
        e.preventDefault(); // prevent history being appended
        setHeadId(myHeadId);
    }

    return (<>
        <h4 className="mt-2 mb-3 fw-bold">
            Pluto
            <OverlayTrigger placement="auto" overlay={
                <Tooltip id="tooltipTitle" className="lh-sm">
                    <div className="fs-p12 my-1">
                        <span className="text-warning">Pluto </span>
                        uses AI to track and project production yields by consolidating company-wide data sources across current and historical timelines.
                    </div>
                </Tooltip>}>
                <img src="/images/pluto.png" className="img-fluid ms-2 align-bottom" alt="Pluto" width="28" />
            </OverlayTrigger>
        </h4>

        <div className="mt-3 text-danger fw-bold">Work in Progress</div>
        <div>Alpha released expected on 11 Oct 2024</div>
        <br />

        {/* <ul className="nav nav-pills mb-3">
            <li className="nav-item">
                <button className={"nav-link py-1x" + (headId===0 ? " active" : "")}
                    onClick={(e) => handleClickTab(e, 0)}>Report</button>
            </li>
            <li className="nav-item">
                <button className={"nav-link py-1x" + (headId===1 ? " active" : "")}
                    onClick={(e) => handleClickTab(e, 1)}>Inference</button>
            </li>
            <li className="nav-item">
                <button className={"nav-link py-1x" + (headId===2 ? " active" : "")}
                    onClick={(e) => handleClickTab(e, 2)}>Explorer</button>
            </li>
            <li className="nav-item">
                <button className={"nav-link py-1x" + (headId===3 ? " active" : "")}
                    onClick={(e) => handleClickTab(e, 3)}>Jobs</button>
            </li>
        </ul>

        { headId === 3 && <SonicJobs /> } */}
    </>);
}
export default PlutoIndex;
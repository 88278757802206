import secureLocalStorage from "react-secure-storage";
import { ENV_TYPE } from "../Config";
// import sanitizeHtml from 'sanitize-html';
// 7/9/2024: added polyfill to allow older browsers to use AbortController (eg samsung tv)
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

// given this is sent via a handle (const handleLogin) and doesnt run on first instance
// cannot make it as a hook. so cannot be useFetchEmail etc
async function restQuery({queryUrl, useToken})
{
    //https://dmitripavlutin.com/javascript-fetch-async-await/
    if (ENV_TYPE !== "PRODUCTION")
        console.log("DEBUG> REST query: " + queryUrl);

    const token = JSON.parse(secureLocalStorage.getItem("token"));
    const abortController = new AbortController();

    try
    {
        const retValue = useToken ? await fetch(queryUrl, { signal: abortController.signal,
                        method: "GET",
                        headers: {
                            "Authorization": "Bearer " + token,
                            "Content-type": "application/json"
                        }})
                        : await fetch(queryUrl, { signal: abortController.signal,
                            method: "GET",
                            headers: { "Content-type": "application/json" }});

        if (!retValue.ok)
            throw new Error(`HTTP error! status: ${retValue.status}`);

        const jsonText = await retValue.text();

        // if (ENV_TYPE !== "PRODUCTION")
        // {
        //     // TODO CWE-117: log injection. user inputs must be sanitized before they are logged
        //     console.log("DEBUG> jsonText: " + sanitizeHtml(jsonText));
        //     //console.log(retValue); // returns a promise
        // }

        return { "ok": true, "text": jsonText };
    }
    catch (err)
    {
        // TODO: BUG HERE, since return values that have error (eg HTTP.BAD) will return no corresponding text from server eventhough it is detailed
        //console.log(retValue);
        //console.log("RestQuery ERROR: " + err);
        //console.log(err);
        //setTimeout(() => console.log(err), 1000);

        if (err.name === "AbortError")
            console.log("RestQuery ABORTED! " + queryUrl);
        else
            console.log("RestQuery ERROR: " + err + ", query: " + queryUrl);
        //throw Error("Bad Response: " + retValue.statusText);
        const retValue = { "ok": false, "status": err.status, "message": err.message };
        //console.log("RestQuery.RESPONSE: " + JSON.stringify(retValue));
        return retValue;
    }
};

const RestQuery = { restQuery }

export default RestQuery;


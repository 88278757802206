import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './index.addon.css'; // for full list of bootstrap colors: 10/9/2024
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";

// const root = ReactDOM.createRoot(document.getElementById('root'));
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// for HMR hot reloading: 27/5/2024
// if (module.hot)
// 	module.hot.accept();

root.render(
	// <React.StrictMode>
		<App />
	// </React.StrictMode>
);

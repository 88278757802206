// TITLE HEADER (SONIC)

import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

// HEADER FOR TABS
function SonicTabs({ headId })
{
    return (<>

        <h4 className="mt-2 mb-3 fw-bold">
            Sonic
            <OverlayTrigger placement="auto" overlay={
                <Tooltip id="tooltipTitle" className="lh-sm">
                    <div className="fs-p12 my-1">
                        <span className="text-warning">Sonic </span>
                        uses proprietary computer vision AI to detect fresh fruit bunches in photos, and provide enhanced harvest supervision.
                    </div>
                </Tooltip>}>
                <img src="/images/sonic.png" className="img-fluid ms-2 align-bottom" alt="Sonic" width="30" />
            </OverlayTrigger>
        </h4>


        <ul className="nav nav-tabs mb-3 flex-nowrap">
            <li className="nav-item ">
                <a className={"nav-link py-1 px-2x" + (headId===0 ? " active fw-bold border-bottom bg-secondary bg-opacity-25" : "")}
                    href="/app/sonic/infer/listreport">Report</a>
            </li>
            <li className="nav-item">
                <a className={"nav-link py-1 px-2x" + (headId===1 ? " active fw-bold border-bottom bg-secondary bg-opacity-25" : "")}
                    href="/app/sonic/infer/listbyimg">Inference</a>
            </li>

            <li className="nav-item">
                <a className={"nav-link py-1 px-2" + (headId===2 ? " active fw-bold border-bottom bg-secondary bg-opacity-25" : "")}
                    href="/app/sonic/infer/listflags">Flagged</a>
            </li>
            {/* <li className="nav-item">
                <a className={"disabled nav-link py-1 px-2" + (headId===2 ? " active fw-bold border-bottom bg-secondary bg-opacity-25" : "")}
                    href="/app/sonic/infer/explorer">Explorer</a>
            </li> */}

            <li className="nav-item">
                <a className={"nav-link py-1 px-2x" + (headId===3 ? " active fw-bold border-bottom bg-secondary bg-opacity-25" : "")}
                    href="/app/sonic/infer/listjobs">Jobs</a>
            </li>

            {/* disable tab */}
            {/* <li className="nav-item">
                <a className={"disabled nav-link py-1" + (headId===4 ? " active fw-bold border-bottom bg-secondary bg-opacity-25" : "")}
                    href="/app/sonic/info">Info</a>
            </li> */}
        </ul>

    </>);
}
export default SonicTabs;
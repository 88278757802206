import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SonicImage from "./SonicImage";
import { getViewportWidthSize, getViewportWidthType } from "../Config";
import { cellStyleTight } from "../components/Utils";
import { getTphFormatValue, getTphHeaderName } from "./helper/ColFormatInferTph";
import { getFruitFormatValue, getFruitHeaderName } from "./helper/ColFormatInferFruit";

// columns to display as INFERENCE STATS
const inferColList = [
    "totalBunches",
    "mainCount",
    // "diff",
    "meanConfidence",
    "dImg2Record",
    "dImg2Tph",
    // "inferenceDuration",
    "inferenceDate",
    // "width", "height",
    // "isLandscape"
];
const inferColumnCount = Math.min(3, Math.ceil(inferColList.length / 2)); // Adjust the number of columns based on the length

// NOTE: 16/9/2024: when using collapse function in bootstrap, page will move when hiding/showing
// using d-none to show/hide annotations will not move the page unituitively but less elegant
// as it doesnt hide/show in a smooth transition



// THIS DISPLAYS ONLY A SINGLE IMAGE
//
// imgData: this is from imgData[rowId]
// setPageType: call back function for "going back" by setting to "list"
// inferFruitData: the entire rows for inference output, so that can go back/fwd
function SonicImgViewSingle({imgLocation, imgData, imgCount,
                             imgDataKey, rowIndex, inferFruitData,
                             widthSize, widthType
})
{
    // console.log(imgData);
    // checkbox to DRAW ANNOTATIONS
    const [drawAnnotations, setDrawAnnotations] = useState(true);
    const handleDrawAnnotations = (event) => setDrawAnnotations(event.target.checked);


    // to display list of annotations // default to not show
    // this is not used by showing/not showing (we rely on bootstrap code) but is to display SHOW/HIDE in option
    const [showAnnotationList, setShowAnnotationList] = useState(false);

    // TODO: REMOVE?
    // SHOW INFERENCE DETAILS // used by checkbox
    const [showInferDetails, setShowInferDetails] = useState(true);


    // DICT TO STORE SELECTED STATE OF INDV ANNOTATED FRUIT
    // id => true/false (selected/unselected)
    const [indvAnnotationSelectMap, setIndvAnnotationSelectMap] = useState({});
    // on new image, reset all indv annotations to be enabled
    useEffect(()=>
    {
        var selectMap = {};
        if (imgData && imgData.annotations)
            for (var i = 0; i < imgData.annotations.length; i++) selectMap[i] = true;
        setIndvAnnotationSelectMap(selectMap);
        // console.log("set all to true> rowId: " + rowIndex + ", size: " + imgData.annotations.length);
        // console.log(selectMap);
    }, [imgData, rowIndex]);
    const handleIndvAnnotationSelection = (rowId) =>
    {
        setIndvAnnotationSelectMap(prevState =>
        {
            const newState = { ...prevState };
            newState[rowId] = !prevState[rowId];
            return newState;
        });
    }

    // SHOW FRUIT TEXT in annotation (if false, display just the confidence number)
    const [includeFruitText, setIncludeFruitText] = useState(true);
    const handleIncludeFruitText = (event) => setIncludeFruitText(event.target.checked);

    const colClassName = "bg-info bg-opacity-10 border-secondary border-opacity-25";

    return (<div className="mb-2">

        {/* <div>inferColumnCount {inferColumnCount}</div> */}


        {/* ===== IMAGE TITLE: 1 OF X ===== */}
        <div className="text-purple-500 fs-p14X fw-bold mb-1 mt-2">Image {imgDataKey} of {imgCount}</div>


        {/* ===== TABLE DATA: RECORD FIELDS ===== */}
        <div className="d-flex mb-1">
            <table className="table small w-100x w-auto table-condensed table-hover mb-0 fs-p13" align="left">
                <tbody>
                    {/* ===== INFERENCE DATA ===== */}
                    {/* {showInferDetails && widthType === "xs" && inferColList.map((colName, idx) => {
                        return (<tr key={idx} className={colClassName}>{displayColValue(colName, rowIndex, inferFruitData, "bg-secondary bg-opacity-10")}</tr>); })} */}
                    {showInferDetails && <>

                        {Array.from({ length: inferColumnCount }).map((_, rowIndex) => (
                            <tr key={rowIndex} className={colClassName}>
                                {Array.from({ length: inferColumnCount }).map((_, colIndex) =>
                                {
                                    const id = rowIndex + colIndex * inferColumnCount;
                                    if (id < inferColList.length)
                                    {
                                        const colName = inferColList[id];
                                        const value = imgData[colName];
                                        const [aValue, aClassName] = getFruitFormatValue(colName, value, inferFruitData[rowIndex].imgData, "text-end");
                                        const newColName = getFruitHeaderName(colName);
                                        // console.log("id: " + id + ", value: " + aValue + ", colName: " + colName + ", newColName: " + newColName);
                                        return (
                                        <React.Fragment key={colName}>
                                            <td style={cellStyleTight} className={colClassName}>{newColName}</td>
                                            <td style={cellStyleTight} className={aClassName + " " + colClassName}>{aValue}</td>
                                            { colIndex < inferColumnCount - 2 && <td style={cellStyleTight} className={colClassName}></td> }
                                        </React.Fragment>
                                        );
                                    }
                                    return null;
                                })}
                            </tr>
                            ))}

                    </>}
                </tbody>
            </table>
        </div>




        {/* ===== IMAGE + ENABLE/DISABLE ANNOTATIONS CHECKBOX ===== */}
        <div className="d-flex align-items-baseline fs-p14">
            <span className="form-check">
                {/* CHECKBOX: ENABLE/DISABLE DISPLAY OF ALL ANNOTATIONS */}
                <input className="form-check-input" type="checkbox" value="" id="checkDrawAnnotations"
                    checked={drawAnnotations} onChange={handleDrawAnnotations}/>
                <label className="form-check-label" htmlFor="checkDrawAnnotations">Annotations</label>
                {/* SHOW/HIDE LINK: LISTING OF ALL ANNOTATIONS */}
                {/* <a href="#" className="ms-2 fs-p13" data-bs-toggle="collapse" data-bs-target="#collapseAnnotationList"
                    aria-expanded="false" aria-controls="collapseAnnotationList"
                    onClick={(e) => { e.preventDefault(); setShowAnnotationList(!showAnnotationList); }}
                    >{ showAnnotationList ? "hide" : "show"}</a> */}
                <a href="#" className="ms-2 fs-p13"
                    onClick={(e) => { e.preventDefault(); setShowAnnotationList(!showAnnotationList); }}
                    >{ showAnnotationList ? "hide" : "show"}</a>
            </span>
            <span className="fs-p13">
                {/* CHECKBOX: DISPLAY ANNOTATION WITH FRUIT AS TEXT, IF UNCHECKED, JUST CONFIDENCE IS SHOWN */}
                <input className="ms-5 form-check-input" type="checkbox" value="" id="checkShowFruitText"
                    checked={includeFruitText} onChange={handleIncludeFruitText}/>
                <label className="ms-2 form-check-label" htmlFor="checkShowFruitText">Text in annotations</label>
            </span>
        </div>
        {/* ===== DISPLAYS LIST OF INDIVIDUAL ANNOTATIONS (SHOW/HIDE) ===== */}
        {/* <div className="collapse" id="collapseAnnotationList"> */}
        {/* moving away fm collapse function, to use d-none to prevent unintuitive movement of page */}
        <div className={showAnnotationList ? "" : "d-none"}>
            {displayAnnotationList(imgData, widthSize, drawAnnotations,
                                   indvAnnotationSelectMap, setIndvAnnotationSelectMap,
                                   handleIndvAnnotationSelection)}
        </div>


        {/* ===== IMAGE: WITH ANNOTATIONS ===== */}
        <div>{<SonicImage imgLocation={imgLocation}
                imgDataRow={imgData}
                drawAnnotations={drawAnnotations}
                indvAnnotationSelectMap={indvAnnotationSelectMap}
                includeFruitText={includeFruitText} />}</div>

    </div>);
}

function displayAnnotationList(data, widthSize, drawAnnotations,
                               indvAnnotationSelectMap, setIndvAnnotationSelectMap,
                               handleIndvAnnotationSelection)
{
    const annotations = data.annotations;
    if (!annotations) return null;
    // annotations is a list. each row is a string with format: "classId x y x1 y1 confidence". extract confidence
    // const confidences = annotations.map(row => parseFloat(row.split(" ")[-1]));
    const confidences = annotations.map(row => parseFloat(row.split(" ").pop()));
    const annotationLength = annotations.length;
    var maxColumns = 5; // Set the maximum number of columns you want to support
    if (widthSize < 300)
        maxColumns = 1;
    else if (widthSize < 450)
        maxColumns = 2;
    else if (widthSize < 600)
        maxColumns = 3;
    else if (widthSize < 750)
        maxColumns = 4;
    const columns = Math.min(maxColumns, Math.ceil(annotationLength / 3)); // Adjust the number of columns based on the length

    return (<div className="fs-p14 mt-2">

        {/* ===== MEAN CONFIDENCE ===== */}
        {/* <div><b>Confidences:</b> {confidences.map((item) => (100 * parseFloat(item)).toFixed(1)).join(", ")}</div> */}
        <div className="mb-3">
            <b>Mean Confidence:</b> {(100 * confidences.reduce((a, b) => a + b, 0) / confidences.length).toFixed(1)}
            {/* TOGGLE ALL OR NOTHING FOR ANNOTATIONS SELECTION */}
            <button type="button" className="btn btn-primary btn-sm ms-4 fs-p12 py-0"
                onClick={() =>
                {
                    // set ALL rowId keys in indvAnnotationSelectMap to true
                    var selectMap = {};
                    for (var i = 0; i < annotationLength; i++) selectMap[i] = true;
                    setIndvAnnotationSelectMap(selectMap);
                }}>All</button>
            <button type="button" className="btn btn-secondary btn-sm ms-1 fs-p12 py-0"
                onClick={() =>
                {
                    // set ALL rowId keys in indvAnnotationSelectMap to false
                    var selectMap = {};
                    for (var i = 0; i < annotationLength; i++) selectMap[i] = false;
                    setIndvAnnotationSelectMap(selectMap);
                }}>None</button>
        </div>
        {/* <div className="mt-2 mb-1"><b>Annotations:</b> {annotationLength}</div> */}

        {/* ===== INDIVIDUAL ANNOTATION LISTINGS ===== */}
        { annotationLength <= 3
            ? <div>{annotations.map((row, idx) =>
                <div key={idx}>{outputSingleAnnotation(idx, row, drawAnnotations,
                    indvAnnotationSelectMap, handleIndvAnnotationSelection)}</div>)}</div>
            : <div className="row">
                {Array.from({ length: columns }).map((_, colIndex) => (
                    <div className="col" key={colIndex} style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {getColumnAnnotations(colIndex, annotationLength, columns, annotations).map((row, idx) =>
                            <div key={idx + colIndex * Math.ceil(annotationLength / columns)}>
                                {outputSingleAnnotation(idx + colIndex * Math.ceil(annotationLength / columns),
                                    row, drawAnnotations, indvAnnotationSelectMap, handleIndvAnnotationSelection)}
                            </div>
                        )}
                    </div>
                ))}
            </div>}
        <p></p>
    </div>);
}

// returns the slice of relevant annotations for that specific column
// small screens only have 1 column to display
// bigger screens have up to 5 columns. this helper method chunks the annotations into relevant columns
const getColumnAnnotations = (colIndex, annotationLength, columns, annotations) =>
{
    const itemsPerColumn = Math.ceil(annotationLength / columns);
    const start = colIndex * itemsPerColumn;
    const end = start + itemsPerColumn;
    return annotations.slice(start, end);
};


const getColumnInferDetails = (colIndex, colLength, inferColumnCount, allColumns) =>
{
    const itemsPerColumn = Math.ceil(colLength / inferColumnCount);
    const start = colIndex * itemsPerColumn;
    const end = start + itemsPerColumn;
    // console.log("colLength: " + colLength + ", colIndex: " + colIndex + ", start: " + start + ", end: " + end);
    return allColumns.slice(start, end);
};


// outputs the indv annotations to the format that we want
function outputSingleAnnotation(idx, row, drawAnnotations,
                                indvAnnotationSelectMap, handleIndvAnnotationSelection)
{
    const parts = row.split(" ")
    // first is the classId, last is the confidence
    const classId = parts[0]; // The first value is the classId
    const confidence = (100 * parts[parts.length - 1]).toFixed(1); // The last value is the confidence
    const coordinates = parts.slice(1, -1).map(num => parseFloat(num).toFixed(4)).join(", "); // The middle values are the coordinates

    return (
    <span className="form-checkx">
        <input className="form-check-input" disabled={!drawAnnotations} type="checkbox"
            // need to make id here UNIQUE! or else the first checkbox will always turn on/off on clicks outside
            value="" id={"checkDrawSingleAnnotation" + idx}
            // indvAnnotationSelectMap[idx] might be undefined on startup
            checked={ indvAnnotationSelectMap[idx] || false} onChange={e => handleIndvAnnotationSelection(idx)}/>
        {/* idx: starts from 0, so increment 1 to begin fm 1, more intuitive */}
        <label className={"form-check-label ms-1" + (drawAnnotations ? "" : " text-muted")} htmlFor={"checkDrawSingleAnnotation" + idx}>
            <span><span className="badge bg-secondary bg-opacity-50 text-primary">{idx + 1}</span> {confidence}% {classId === "0" ? <i className="bi bi-sun"></i> : classId === "1" ? "loose" : ""}</span>
            {/* <span><span className="badge bg-secondary bg-opacity-50 text-primary">{idx}</span> {confidence}% {classId === "0" ? "fruit" : classId === "1" ? "loose" : ""}</span> */}
            {/* <span><span className="badge bg-primary text-secondary-subtle">{idx}</span> {confidence}% {classId === "0" ? "fruit" : classId === "1" ? "loose" : ""}</span> */}
        </label>
    </span>);
}



function displayColValue(colName, rowIndex, imgDataRow, bgColorClassName="")
{
    const value = imgDataRow[colName];
    // console.log("value: " + value + ", colName: " + colName);
    const [aValue, aClassName] = getFruitFormatValue(colName, value, imgDataRow, "text-end");
    const newColName = getFruitHeaderName(colName);
    // return <div className={aClassName}>{newColName}: {aValue}</div>
    return <React.Fragment key={colName}><td style={cellStyleTight} className={bgColorClassName}>{newColName}</td><td style={cellStyleTight} className={aClassName + " " + bgColorClassName}>{aValue}</td></React.Fragment>
}

export default SonicImgViewSingle;

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SonicImage from "./SonicImage";
import { getFruitFormatValue, getFruitHeaderName } from "./helper/ColFormatInferFruit";
import { ENV_TYPE, getViewportWidthSize, getViewportWidthType, HOST, PYTHON_PORT } from "../Config";
import { cellStyle } from "../components/Utils";
import RestQuery from "../components/RestQuery";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// columns to display as INFO
const infoColList = ["createDate", "totalBunches", "mainCount", "diff", "error%", "harvesterCount", "imgCount", "imgID",
    // "inferenceDuration", "meanConfidence",
    "estate", "estCode", "harvestAfd", "harvestBlock", "harvestPlatformName", "createBy", "mechanizationFlag", "idTPanen",
    // "inferenceDate"
];
// columns to display as INFERANCE STATS
const inferColList = ["meanConfidence", "inferenceDuration", "inferenceDate", "width", "height", "isLandscape"];

const infoColHalf = Math.ceil(infoColList.length / 2);
const infoColList1 = infoColList.slice(0, infoColHalf);
const infoColList2 = infoColList.slice(infoColHalf);

const inferColHalf = Math.ceil(inferColList.length / 2);
const inferColList1 = inferColList.slice(0, inferColHalf);
const inferColList2 = inferColList.slice(inferColHalf);



const flagAddUrl = "https://" + HOST + ":" + PYTHON_PORT + "/sonic/addflag";
const flagRemoveUrl = "https://" + HOST + ":" + PYTHON_PORT + "/sonic/removeflag";
const bookmarkColorSelected = "#a51941"; //"#d22d63";
const bookmarkColorUnselected = "#3d3f45"; //"#303236";
// export const bookmarkClassSelected = "bi bi-bookmark-fill";
const bookmarkClassSelected = "bi bi-bookmark-plus-fill";
const bookmarkClassUnselected = "bi bi-bookmark";


// data: this is from imgData[X]
// setPageType: call back function for "going back" by setting to "list"
// inferFruitData: the entire rows for inference output, so that can go back/fwd
function SonicImageView({imgLocation, imgData, setPageType, rowIndex, inferFruitData,
                         flagImgMap, setFlagImgMap
})
{
    // check page width, to determine whether to display 1 column table, or 2 column
    // when widthType==xs, then will be 1 column only table
    const [widthType, setWidthType] = useState(getViewportWidthType());
    const [widthSize, setWidthSize] = useState(getViewportWidthSize());
    const notify = function()
    {
        setWidthType(getViewportWidthType());
        setWidthSize(getViewportWidthSize());
        //console.log("You just resized the browser window: " + widthType)
        // console.log("width: " + Math.max(document.documentElement.clientWidth, window.innerWidth));
    }
    window.addEventListener("resize", notify)


    const totalRows = inferFruitData.length;
    // console.log(inferFruitData)
	// const searchParams = useSearchParams();
    // // if imgLocation or data are defined in constructor call, then use that. if not, fall back to url params
    // imgLocation = imgLocation || searchParams.get("imgLocation");
    // imgData = imgData || searchParams.get("data");

    const [viewRowIndex, setViewRowIndex] = useState(rowIndex);
    const [viewImgLocation, setViewImgLocation] = useState(imgLocation);
    const [viewImgData, setViewImgData] = useState(imgData);
    // whenever viewRowIndex changes, change the viewImgLocation
    useEffect(() =>
    {
        setViewImgLocation(inferFruitData[viewRowIndex].imgLocation);
        setViewImgData(inferFruitData[viewRowIndex]);
        const imgLocation = inferFruitData[viewRowIndex].imgLocation
        if (imgLocation)
        {
            const imgName = imgLocation.split("/").pop();
            // console.log(flagImgMap);
            const isFlagged = flagImgMap[imgName] || false; // if no imgName in key, then false
            // console.log("isFlagged: " + isFlagged + ", imgName: " + imgName);
            setFlagImage(isFlagged);
        }
        else
            setFlagImage(false);

    }, [viewRowIndex]);

    // checkbox to DRAW ANNOTATIONS
    const [drawAnnotations, setDrawAnnotations] = useState(true);
    const handleDrawAnnotations = (event) => setDrawAnnotations(event.target.checked);


    // to display list of annotations // default to not show
    // this is not used by showing/not showing (we rely on bootstrap code) but is to display SHOW/HIDE in option
    const [showAnnotationList, setShowAnnotationList] = useState(false);

    // SHOW INFERENCE DETAILS // used by checkbox
    const [showInferDetails, setShowInferDetails] = useState(true);


    // DICT TO STORE SELECTED STATE OF INDV ANNOTATED FRUIT
    // id => true/false (selected/unselected)
    const [indvAnnotationSelectMap, setIndvAnnotationSelectMap] = useState({});
    // on new image, reset all indv annotations to be enabled
    useEffect(()=>
    {
        var selectMap = {};
        if (viewImgData && viewImgData.annotations)
            for (var i = 0; i < viewImgData.annotations.length; i++) selectMap[i] = true;
        setIndvAnnotationSelectMap(selectMap);
        // console.log("set all to true> rowId: " + viewRowIndex + ", size: " + viewImgData.annotations.length);
        // console.log(selectMap);
    }, [viewImgData, viewRowIndex]);
    const handleIndvAnnotationSelection = (rowId) =>
    {
        setIndvAnnotationSelectMap(prevState =>
        {
            const newState = { ...prevState };
            newState[rowId] = !prevState[rowId];
            return newState;
        });
    }


    // SHOW FRUIT TEXT in annotation (if false, display just the confidence number)
    const [includeFruitText, setIncludeFruitText] = useState(true);
    const handleIncludeFruitText = (event) => setIncludeFruitText(event.target.checked);


    // FLAG FOR IMAGE
    const [flagImage, setFlagImage] = useState(false);
    function handleFlagChange(e, imgName)
    {
        e.preventDefault();
        // based on flagImage, add or delete accordingly
        const queryFlagUrl = (!flagImage ? flagAddUrl : flagRemoveUrl)
            + "?key=" + imgName + "&userName=testUser";
        // flip flag once triggered
        const retVal = RestQuery.restQuery({ queryUrl: queryFlagUrl, useToken: false })
        .then(
            (response) =>
            {
                if (!response.ok)
                    console.log("ERROR querying flag " + imgName + ": " + response);
                else
                {
                    if (ENV_TYPE !== "PRODUCTION") console.log("RESPONSE: " + response.text);
                    // update flagImgMap
                    const newFlagData = { ...flagImgMap };
                    newFlagData[imgName] = !flagImage;
                    // console.log("setting flagImage: " + !flagImage)
                    console.log(newFlagData);
                    setFlagImgMap(newFlagData);
                    setFlagImage(!flagImage);
                }
            }, (error) =>
            {
                const resMessage = (error.response && error.response.name && error.response.data.message)
                    || error.message || error.toString();
                console.log("FAILED to handle flag " + imgName + ": " + resMessage);
            }
        );
        // console.log("RESULT: " + retVal);
    }



    return (<div>

        {/* ===== BUTTON: BACK TO LIST ===== */}
        <div className="d-flex align-items-center mb-3 mt-2">
            <button type="button" className="btn btn-info btn-sm py-0"
                onClick={(e) =>
                {
                    e.preventDefault();
                    setPageType("list");
                }}><i className="bi bi-arrow-left-circle"></i> Listings</button>

            <span className="form-checkx ms-4 fs-p13">
                <input className="form-check-input" type="checkbox" value="" id="checkShowInferData"
                            checked={showInferDetails} onChange={() => setShowInferDetails(!showInferDetails)}/>
                <label className="form-check-label ms-2" htmlFor="checkShowInferData">Info</label>
            </span>
        </div>


        {/* ===== BUTTON: PREV + NEXT ===== */}
        {/* SHOW PREV + NEXT. first check what line the fileName is at to see if there is a next/prev */}
        <div className="mb-3 d-flex align-items-center">
            {/* <button type="button" className={"btn btn-secondary btn-sm py-0 px-1 fs-9"} disabled={rowIndex <= 0 ? true : false} onClick={() => router.push("?p=details&name=" + name + "&id=" + pageId + "&filename=" + summaryData[rowIndex - 1]["filename"]) }>Prev</button> */}
            <button type="button" className={"btn btn-secondary btn-smX py-0 px-2"} disabled={viewRowIndex <= 0 ? true : false} onClick={(e) => { e.preventDefault(); setViewRowIndex(viewRowIndex - 1);} }><i className="bi bi-arrow-left-circle me-2"></i>Prev</button>
            <span className="mx-2">{viewRowIndex + 1} of {totalRows}</span>
            {/* <button type="button" className="btn btn-secondary btn-sm py-0 px-1 fs-9" disabled={rowIndex >= totalRows - 1 ? true : false} onClick={() => router.push("?p=details&name=" + name + "&id=" + pageId + "&filename=" + summaryData[rowIndex + 1]["filename"])}>Next</button> */}
            <button type="button" className="btn btn-secondary btn-smX py-0 px-2" disabled={viewRowIndex >= totalRows - 1 ? true : false} onClick={(e) => { e.preventDefault(); setViewRowIndex(viewRowIndex + 1) }}>Next<i className="ms-2 bi bi-arrow-right-circle"></i></button>
        </div>


        {/* ===== TABLE DATA: RECORD FIELDS ===== */}
        <div className="d-flex mb-3">
            <table className="table small w-100x w-auto table-condensed table-hover mb-0" align="left">
                <tbody>
                    {/* ===== RECORDS DATA ===== */}
                    {widthType === "xs" && infoColList.map((colName, idx) => {
                        return (<tr key={idx}>{displayColValue(colName, viewRowIndex, inferFruitData)}</tr>); })}
                    {widthType !== "xs" && <>
                        {Array.from({ length: Math.max(infoColList1.length, infoColList2.length) }).map((_, idx) => (
                        <tr key={idx}>
                            {infoColList1[idx] ? displayColValue(infoColList1[idx], viewRowIndex, inferFruitData) : null}
                            {/* spacing */}
                            <td></td>
                            {infoColList2[idx] ? displayColValue(infoColList2[idx], viewRowIndex, inferFruitData) : null}
                        </tr>))}
                    </>}
                    {/* ===== INFERENCE DATA ===== */}
                    {showInferDetails && widthType === "xs" && inferColList.map((colName, idx) => {
                        return (<tr key={idx} className="bg-secondary bg-opacity-10 border-secondary border-opacity-25">{displayColValue(colName, viewRowIndex, inferFruitData, "bg-secondary bg-opacity-10")}</tr>); })}
                    {showInferDetails && widthType !== "xs" && <>
                        {Array.from({ length: Math.max(inferColList1.length, inferColList2.length) }).map((_, idx) => (
                        <tr key={idx}>
                            {inferColList1[idx] ? displayColValue(inferColList1[idx], viewRowIndex, inferFruitData, "bg-secondary bg-opacity-10 border-secondary border-opacity-25") : null}
                            {/* spacing */}
                            <td className="bg-secondary bg-opacity-10 border-secondary border-opacity-25"></td>
                            {inferColList2[idx] ? displayColValue(inferColList2[idx], viewRowIndex, inferFruitData, "bg-secondary bg-opacity-10 border-secondary border-opacity-25") : null}
                        </tr>))}
                    </>}
                </tbody>
            </table>
        </div>




        {/* ===== IMAGE + ENABLE/DISABLE ANNOTATIONS CHECKBOX ===== */}
        <div className="d-flex align-items-baseline fs-p14">
            <span className="form-check">
                {/* CHECKBOX: ENABLE/DISABLE DISPLAY OF ALL ANNOTATIONS */}
                <input className="form-check-input" type="checkbox" value="" id="checkDrawAnnotations"
                    checked={drawAnnotations} onChange={handleDrawAnnotations}/>
                <label className="form-check-label" htmlFor="checkDrawAnnotations">Annotations</label>
                {/* SHOW/HIDE LINK: LISTING OF ALL ANNOTATIONS */}
                <a href="#" className="ms-2 fs-p13" data-bs-toggle="collapse" data-bs-target="#collapseAnnotationList"
                    aria-expanded="false" aria-controls="collapseAnnotationList"
                    onClick={(e) => { e.preventDefault(); setShowAnnotationList(!showAnnotationList); }}
                    >{ showAnnotationList ? "hide" : "show"}</a>
            </span>
            <span className="fs-p13">
                {/* CHECKBOX: DISPLAY ANNOTATION WITH FRUIT AS TEXT, IF UNCHECKED, JUST CONFIDENCE IS SHOWN */}
                <input className="ms-5 form-check-input" type="checkbox" value="" id="checkShowFruitText"
                    checked={includeFruitText} onChange={handleIncludeFruitText}/>
                <label className="ms-2 form-check-label" htmlFor="checkShowFruitText">Text in annotations</label>
            </span>


            {/* ===== FLAGS ===== */}
            <OverlayTrigger placement="auto" rootClose overlay={
                <Tooltip id="tooltip-copylink" className="lh-sm">
                    Flag Image for Review
                </Tooltip>}>
                <a href="#"
                className="text-left ms-3 fs-p13 text-black" onClick={(e) => handleFlagChange(e, viewImgData.imgName)}
                    style={{textDecoration: "None"}}>
                        { flagImage ? <i className={bookmarkClassSelected} style={{ "color": bookmarkColorSelected }}></i>
                        : <i className={bookmarkClassUnselected + " text-secondary"} ></i> } Flag
                    {/* <i className={"bi bi-bookmark" + (isBookmark ? "-fill text-red-700" : " text-secondary")}></i> */}
                </a>
            </OverlayTrigger>


        </div>
        {/* ===== DISPLAYS LIST OF INDIVIDUAL ANNOTATIONS (SHOW/HIDE) ===== */}
        <div className="collapse" id="collapseAnnotationList">
            {displayAnnotationList(viewImgData, widthSize, drawAnnotations,
                                   indvAnnotationSelectMap, setIndvAnnotationSelectMap,
                                   handleIndvAnnotationSelection)}
        </div>


        {/* ===== IMAGE: WITH ANNOTATIONS ===== */}
        <div>{<SonicImage imgLocation={viewImgLocation}
                imgDataRow={viewImgData}
                drawAnnotations={drawAnnotations}
                indvAnnotationSelectMap={indvAnnotationSelectMap}
                includeFruitText={includeFruitText}
                />}</div>

    </div>);
}

function displayAnnotationList(data, widthSize, drawAnnotations,
                               indvAnnotationSelectMap, setIndvAnnotationSelectMap,
                               handleIndvAnnotationSelection)
{
    const annotations = data.annotations;
    if (!annotations) return null;
    // annotations is a list. each row is a string with format: "classId x y x1 y1 confidence". extract confidence
    // const confidences = annotations.map(row => parseFloat(row.split(" ")[-1]));
    const confidences = annotations.map(row => parseFloat(row.split(" ").pop()));
    const annotationLength = annotations.length;
    var maxColumns = 5; // Set the maximum number of columns you want to support
    if (widthSize < 300)
        maxColumns = 1;
    else if (widthSize < 450)
        maxColumns = 2;
    else if (widthSize < 600)
        maxColumns = 3;
    else if (widthSize < 750)
        maxColumns = 4;
    const columns = Math.min(maxColumns, Math.ceil(annotationLength / 3)); // Adjust the number of columns based on the length

    return (<div className="fs-p14 mt-2">

        {/* ===== MEAN CONFIDENCE ===== */}
        {/* <div><b>Confidences:</b> {confidences.map((item) => (100 * parseFloat(item)).toFixed(1)).join(", ")}</div> */}
        <div className="mb-3">
            <b>Mean Confidence:</b> {(100 * confidences.reduce((a, b) => a + b, 0) / confidences.length).toFixed(1)}
            {/* TOGGLE ALL OR NOTHING FOR ANNOTATIONS SELECTION */}
            <button type="button" className="btn btn-primary btn-sm ms-4 fs-p12 py-0"
                onClick={() =>
                {
                    // set ALL rowId keys in indvAnnotationSelectMap to true
                    var selectMap = {};
                    for (var i = 0; i < annotationLength; i++) selectMap[i] = true;
                    setIndvAnnotationSelectMap(selectMap);
                }}>All</button>
            <button type="button" className="btn btn-secondary btn-sm ms-1 fs-p12 py-0"
                onClick={() =>
                {
                    // set ALL rowId keys in indvAnnotationSelectMap to false
                    var selectMap = {};
                    for (var i = 0; i < annotationLength; i++) selectMap[i] = false;
                    setIndvAnnotationSelectMap(selectMap);
                }}>None</button>
        </div>
        {/* <div className="mt-2 mb-1"><b>Annotations:</b> {annotationLength}</div> */}

        {/* ===== INDIVIDUAL ANNOTATION LISTINGS ===== */}
        { annotationLength <= 3
            ? <div>{annotations.map((row, idx) =>
                <div key={idx}>{outputSingleAnnotation(idx, row, drawAnnotations,
                    indvAnnotationSelectMap, handleIndvAnnotationSelection)}</div>)}</div>
            : <div className="row">
                {Array.from({ length: columns }).map((_, colIndex) => (
                    <div className="col" key={colIndex} style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                        {getColumnAnnotations(colIndex, annotationLength, columns, annotations).map((row, idx) =>
                            <div key={idx + colIndex * Math.ceil(annotationLength / columns)}>
                                {outputSingleAnnotation(idx + colIndex * Math.ceil(annotationLength / columns),
                                    row, drawAnnotations, indvAnnotationSelectMap, handleIndvAnnotationSelection)}
                            </div>
                        )}
                    </div>
                ))}
            </div>}
        <p></p>
    </div>);
}

// returns the slice of relevant annotations for that specific column
// small screens only have 1 column to display
// bigger screens have up to 5 columns. this helper method chunks the annotations into relevant columns
const getColumnAnnotations = (colIndex, annotationLength, columns, annotations) =>
{
    const itemsPerColumn = Math.ceil(annotationLength / columns);
    const start = colIndex * itemsPerColumn;
    const end = start + itemsPerColumn;
    return annotations.slice(start, end);
};

// outputs the indv annotations to the format that we want
function outputSingleAnnotation(idx, row, drawAnnotations,
                                indvAnnotationSelectMap, handleIndvAnnotationSelection)
{
    const parts = row.split(" ")
    // first is the classId, last is the confidence
    const classId = parts[0]; // The first value is the classId
    const confidence = (100 * parts[parts.length - 1]).toFixed(1); // The last value is the confidence
    const coordinates = parts.slice(1, -1).map(num => parseFloat(num).toFixed(4)).join(", "); // The middle values are the coordinates

    return (
    <span className="form-checkx">
        <input className="form-check-input" disabled={!drawAnnotations} type="checkbox"
            // need to make id here UNIQUE! or else the first checkbox will always turn on/off on clicks outside
            value="" id={"checkDrawSingleAnnotation" + idx}
            // indvAnnotationSelectMap[idx] might be undefined on startup
            checked={ indvAnnotationSelectMap[idx] || false} onChange={e => handleIndvAnnotationSelection(idx)}/>
        {/* idx: starts from 0, so increment 1 to begin fm 1, more intuitive */}
        <label className={"form-check-label ms-1" + (drawAnnotations ? "" : " text-muted")} htmlFor={"checkDrawSingleAnnotation" + idx}>
            <span><span className="badge bg-secondary bg-opacity-50 text-primary">{idx + 1}</span> {confidence}% {classId === "0" ? <i className="bi bi-sun"></i> : classId === "1" ? "loose" : ""}</span>
            {/* <span><span className="badge bg-secondary bg-opacity-50 text-primary">{idx}</span> {confidence}% {classId === "0" ? "fruit" : classId === "1" ? "loose" : ""}</span> */}
            {/* <span><span className="badge bg-primary text-secondary-subtle">{idx}</span> {confidence}% {classId === "0" ? "fruit" : classId === "1" ? "loose" : ""}</span> */}
        </label>
    </span>);
}



function displayColValue(colName, rowIndex, inferFruitData, bgColorClassName="")
{
    const value = inferFruitData[rowIndex][colName];
    const [aValue, aClassName] = getFruitFormatValue(colName, value, inferFruitData[rowIndex], "text-end");
    const newColName = getFruitHeaderName(colName);
    // return <div className={aClassName}>{newColName}: {aValue}</div>
    return <><td style={cellStyle} className={bgColorClassName}>{newColName}</td><td style={cellStyle} className={aClassName + " " + bgColorClassName}>{aValue}</td></>
}

export default SonicImageView;

import preval from 'preval.macro' // for displaying build time

export const HOST = process.env.REACT_APP_HOST;
export const ENV_TYPE = process.env.REACT_APP_ENV_TYPE;
export const PYTHON_PORT = process.env.REACT_APP_PYTHON_PORT;

// display build time
export const REACT_BUILD_TIME = preval`module.exports = new Date().getTime();`
document.getElementById('reactBuildTime').innerText = parseDateAsBuild(REACT_BUILD_TIME);
// display deployment type (FLAG AS DEVELOPMENT / PRODUCTION)
document.getElementById('reactDeploymentType').innerText = ENV_TYPE;

export const initConfig = () =>
{
    if (ENV_TYPE !== "PRODUCTION")
    {
        console.log(process.env);
        console.log("DEV> Config ENV_TYPE: " + ENV_TYPE);
        console.log("DEV> Config HOST: " + HOST);
        console.log("DEV> Config REACT_BUILD_TIME: " + REACT_BUILD_TIME);
        console.log("DEV> Config PYTHON_PORT: " + PYTHON_PORT);
    }
}

function parseDateAsBuild(time)
{
    const date = new Date(time);
    return "" + date.getFullYear()
        + (date.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })
        + date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 })
        + date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 })
        + date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 });
}

// to get the width type thats visible
//https://stackoverflow.com/questions/45394053/how-to-detect-screen-size-or-view-port-on-bootstrap-4-with-javascript
export const getViewportWidthType = () =>
{
    // https://stackoverflow.com/a/8876069
    const width = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0)
    if (width < 576) return 'xs'
    if (width < 768) return 'sm'
    if (width < 992) return 'md'
    if (width < 1200) return 'lg'
    if (width < 1400) return 'xl'
    return 'xxl'
}

export const getViewportWidthSize = () =>
{
    return Math.max(document.documentElement.clientWidth,
        window.innerWidth || 0)
}

// export
// {
//     initConfig, HOST, ENV_TYPE, PYTHON_PORT, REACT_BUILD_TIME
// }